import React, { useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, Polygon, GeoJSON } from 'react-leaflet';
import L, { Marker as LMarker } from 'leaflet';
import ImageOverlayRotated from 'components/maps/ImageOverlayRotated';
import { AlignmentDto, LatLngDto } from 'apis/VenueApi';
import MapTileControl from './MapTileControl';
import { AlignResult } from 'utils/AlignmentUtil';

export interface IHelpConfig {
    opacity: number;
    stroke: boolean;
    showBound: boolean;
    grayscale: boolean;
}

type AlignmentMapViewProps = {
    editable?: boolean;
    initCenter: LatLngDto;
    alignResult?: Partial<AlignResult | AlignmentDto>;
    imageUrl: string;
    onCenterChange?: (center: LatLngDto) => void;
    geojsonData?: any;
    children?: string | JSX.Element | JSX.Element[];
    config?: IHelpConfig;
};

const EditorMoveIcon = L.icon({
    iconUrl:
        'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzQiIGhlaWdodD0iMzQiIHZpZXdCb3g9IjAgMCAzNCAzNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxjaXJjbGUgZmlsbD0iIzNBM0EzQSIgY3g9IjE3IiBjeT0iMTciIHI9IjE3Ii8+PHBhdGggZD0iTTE3Ljk3NTYwOTggMTYuMDI0MzkwMmg0Ljg3ODA0ODd2LTMuMTcwNzMxN0wyNyAxN2wtNC4xNDYzNDE1IDQuMTQ2MzQxNXYtMy4xNzA3MzE3aC00Ljg3ODA0ODd2NC44NzgwNDg3aDMuMTcwNzMxN0wxNyAyN2wtNC4xNDYzNDE1LTQuMTQ2MzQxNWgzLjE3MDczMTd2LTQuODc4MDQ4N2gtNC44NzgwNDg3djMuMTcwNzMxN0w3IDE3bDQuMTQ2MzQxNS00LjE0NjM0MTV2My4xNzA3MzE3aDQuODc4MDQ4N3YtNC44NzgwNDg3aC0zLjE3MDczMTdMMTcgN2w0LjE0NjM0MTUgNC4xNDYzNDE1aC0zLjE3MDczMTd2NC44NzgwNDg3eiIgZmlsbD0iI0ZGRiIvPjwvZz48L3N2Zz4K',
    iconSize: [34, 34],
});
const AlignmentMapView: React.FC<AlignmentMapViewProps> = (props) => {
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const markerPosRef = useRef<LatLngDto>(props.initCenter);

    const geoJsonObj = useMemo(() => {
        if (props.geojsonData) {
            if (typeof props.geojsonData === 'string' && props.geojsonData?.includes('Feature')) {
                try {
                    const obj = JSON.parse(props.geojsonData);
                    return obj;
                } catch (ex) {
                    /* do nothing */
                }
            } else if (Array.isArray(props.geojsonData)) {
                return {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                coordinates: [props.geojsonData],
                                type: 'Polygon',
                            },
                        },
                    ],
                };
            }
        }
        return undefined;
    }, [props.geojsonData]);

    const markerRef = useRef<LMarker>(null);
    const eventHandlers = useMemo(
        () => ({
            mousedown() {
                setIsDragging(true);
            },
            mousemove() {
                const marker = markerRef.current as any;
                if (marker != null && isDragging) {
                    if (props.onCenterChange) props.onCenterChange(marker.getLatLng());
                }
            },
            mouseup() {
                setIsDragging(false);
                const marker = markerRef.current as any;
                markerPosRef.current = marker.getLatLng();
            },
        }),
        [isDragging],
    );

    const mPositions = useMemo(() => {
        if (!props.alignResult?.corners) return undefined;
        const mCorners = props.alignResult.corners;
        const mMarker = markerRef.current as any;
        if (!isDragging) {
            markerPosRef.current = props.alignResult.center!;
            if (!!mMarker?.setLatLng) mMarker.setLatLng(props.alignResult.center);
        }
        return Array.isArray(mCorners)
            ? mCorners
            : [
                  [mCorners.tl.lat, mCorners.tl.lng],
                  [mCorners.tr.lat, mCorners.tr.lng],
                  [mCorners.br.lat, mCorners.br.lng],
                  [mCorners.bl.lat, mCorners.bl.lng],
              ];
    }, [props.alignResult?.corners]);

    return (
        <MapContainer
            center={props.initCenter}
            zoom={18}
            scrollWheelZoom={true}
            zoomControl={false}
            style={{ height: '100%', flex: 1 }}
        >
            <MapTileControl ruler={false} />
            {props.config?.showBound && geoJsonObj ? (
                <GeoJSON
                    key={`geo-json-layer`}
                    data={geoJsonObj}
                    style={{ color: 'purple' }}
                    interactive={false}
                />
            ) : undefined}
            {mPositions ? (
                <>
                    <Polygon
                        positions={mPositions as any}
                        pathOptions={{
                            fillOpacity: 0,
                            stroke: props.config?.stroke !== false,
                            lineCap: 'round',
                        }}
                        interactive={false}
                    />
                    <ImageOverlayRotated
                        imgSrc={props.imageUrl}
                        positions={mPositions as any}
                        opacity={props.config?.opacity ?? 1}
                        grayscale={props.config?.grayscale}
                    />
                </>
            ) : undefined}
            {props.editable ? (
                <Marker
                    draggable={true}
                    eventHandlers={eventHandlers}
                    position={markerPosRef.current}
                    ref={markerRef}
                    icon={EditorMoveIcon}
                />
            ) : undefined}
            {props.children}
        </MapContainer>
    );
};

export default AlignmentMapView;
