import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { useLeafletContext } from '@react-leaflet/core';
import * as L from 'leaflet';
import 'styles/map-style.css';
import { LatLngDto } from 'apis/VenueApi';

interface IMarkerEntity {
    id: number;
    lat: number;
    lng: number;
}
export type MarkerDrawingLayerRef = {
    resetAll: () => void;
    addMarker: (id: number, position?: LatLngDto) => void;
    updateById: (id: number, position: LatLngDto) => void;
    removeById: (id: number) => void;
    getMarkers: () => IMarkerEntity[];
};

type MarkerDrawingLayerProps = {
    initMarkers?: IMarkerEntity[];
    onChange?: (item: IMarkerEntity) => void;
    onClick?: (item: IMarkerEntity) => void;
};

const getNumberIcon = (num: number, color?: string): L.DivIcon => {
    const mHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="${color ?? '#7272cf'}"> <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0" /> <circle cx="12" cy="9" r="5.5" fill="#fff" /> <text x="12" y="12" text-anchor="middle" font-family="monospace" font-stretch="condensed" font-size="7.5">${num}</text></svg>`;
    const encodedData = 'data:image/svg+xml;base64,' + window.btoa(mHtml);
    return L.icon({
        iconUrl: encodedData,
        iconSize: [48, 48],
        iconAnchor: [24, 48],
        shadowUrl: 'https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png',
        shadowSize: [50, 50],
        shadowAnchor: [16, 54],
        popupAnchor: [0, -36],
    });
};

const MarkerDrawingLayer = React.forwardRef<MarkerDrawingLayerRef, MarkerDrawingLayerProps>(
    (props, ref) => {
        const context = useLeafletContext();
        const markersRef = useRef<{ id: number; layer: L.Marker }[]>([]);

        useEffect(() => {
            // setup init markers
            if (props.initMarkers) {
                markersRef.current.forEach((el) => el.layer.remove());
                markersRef.current = [];
                for (const el of props.initMarkers) {
                    _appendMarker(el.id, { lat: el.lat, lng: el.lng });
                }
            }
            if (context.map) {
                const bounds = L.latLngBounds([]);
                markersRef.current.forEach((marker) => {
                    bounds.extend([marker.layer.getLatLng().lat, marker.layer.getLatLng().lng]);
                });
                if (bounds.isValid()) {
                    context.map.flyToBounds(bounds);
                }
            }
        }, []);

        const _appendMarker = (id: number, position?: LatLngDto) => {
            const container = context.map;

            const marker = L.marker([position?.lat ?? 0, position?.lng ?? 0], {
                icon: getNumberIcon(id),
                draggable: true,
            }).addTo(container);

            marker.on('dragend', (e) => {
                const { lat, lng } = e.target.getLatLng();
                if (props.onChange) props.onChange({ id, lat, lng });
            });
            marker.on('click', (e) => {
                const { lat, lng } = e.target.getLatLng();
                if (props.onClick) props.onClick({ id, lat, lng });
            });
            markersRef.current.push({ id, layer: marker });
            return marker;
        };

        useImperativeHandle(ref, () => ({
            resetAll() {
                markersRef.current.forEach((el) => el.layer.remove());
                markersRef.current = [];
            },
            addMarker(id: number, position?: LatLngDto) {
                const index = markersRef.current.findIndex((marker) => marker.id === id);
                if (index === -1) {
                    const mPos = position ?? context.map?.getCenter();
                    _appendMarker(id, mPos);
                } else if (!!position) {
                    const marker = markersRef.current[index].layer;
                    marker.setLatLng([position.lat, position.lng]);
                    markersRef.current[index].layer = marker;
                }
            },
            updateById(id: number, position: LatLngDto) {
                const index = markersRef.current.findIndex((marker) => marker.id === id);
                if (index !== -1) {
                    const marker = markersRef.current[index].layer;
                    marker.setLatLng([position.lat, position.lng]);
                    markersRef.current[index].layer = marker;
                } else {
                    _appendMarker(id, position);
                }
            },
            removeById(id: number) {
                const index = markersRef.current.findIndex((marker) => marker.id === id);
                if (index !== -1) {
                    const marker = markersRef.current[index].layer;
                    marker.remove();
                    markersRef.current.splice(index, 1);
                }
            },
            getMarkers() {
                return markersRef.current.map(({ id, layer }) => ({
                    id,
                    lat: layer.getLatLng().lat,
                    lng: layer.getLatLng().lng,
                }));
            },
        }));
        return null;
    },
);

export default MarkerDrawingLayer;
