import { BeaconDto } from 'apis/BeaconApi';

export const exportAsCsv = (name: string, data: BeaconDto[]) => {
    let csvData = '';
    csvData += 'id,uuid,major,minor,weight,threshold,iosThreshold,txPower,isLowPower,isDoorway,lat,lng\r\n';
    for (let i = 0; i < data.length; i++) {
        csvData += data[i].id + ',';
        csvData += data[i].uuid + ',';
        csvData += data[i].major + ',';
        csvData += data[i].minor + ',';
        csvData += data[i].weight + ',';
        csvData += data[i].threshold + ',';
        csvData += (data[i].iosThreshold ?? '') + ',';
        csvData += (data[i].txPower ?? '') + ',';
        csvData += data[i].isLowPower + ',';
        csvData += data[i].isDoorway + ',';
        csvData += (data[i].lat ?? 0) + ',';
        csvData += data[i].lng ?? 0;
        if (i < data.length - 1) {
            csvData += '\r\n';
        }
    }
    const blobObj = new Blob([csvData], { type: '.csv' });
    const downloadLink = window.URL.createObjectURL(blobObj);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = downloadLink;
    a.download = `beaconPlan_${name}.csv`;
    a.click();
    window.URL.revokeObjectURL(downloadLink);
};

export const parseCsv = (fileName: string, csvContent: string) => {
    let planName = 'New Beacon Plan';
    if (fileName.includes('beaconPlan_')) {
        planName = fileName.replace('.csv', '').split('beaconPlan_')[1];
    }
    const rowData = csvContent.split('\r\n');
    const keyMap = new Map();
    const keyData = rowData[0].split(',');
    keyData.forEach((val, i) => keyMap.set(val, i));

    const safeGet = (data: string[], key: string, fallback?: any) => {
        const mIndex = keyMap.get(key);
        if (mIndex !== undefined && mIndex > -1 && mIndex < data.length) {
            return data[mIndex] ? (data[mIndex]?.length > 0 ? data[mIndex] : fallback) : fallback;
        } else {
            return fallback;
        }
    };
    const newBeaconArray: BeaconDto[] = [];
    for (let i = 1; i < rowData.length; i++) {
        const beaconRow = rowData[i].split(',');
        const newBeaconRecord: BeaconDto = {
            id: safeGet(beaconRow, 'id') ?? i - 1,
            uuid: safeGet(beaconRow, 'uuid'),
            major: parseInt(safeGet(beaconRow, 'major', '0')),
            minor: parseInt(safeGet(beaconRow, 'minor', '0')),
            weight: parseFloat(safeGet(beaconRow, 'weight', '1.0')),
            threshold: parseInt(safeGet(beaconRow, 'threshold', '-75')),
            iosThreshold: parseInt(safeGet(beaconRow, 'iosThreshold', '-75')),
            txPower: safeGet(beaconRow, 'txPower')
                ? parseInt(safeGet(beaconRow, 'txPower'))
                : undefined,
            isLowPower:
                safeGet(beaconRow, 'isLowPower', '')?.toLowerCase() == 'true' ? true : false,
            isDoorway: safeGet(beaconRow, 'isDoorway', '')?.toLowerCase() == 'true' ? true : false,
            lat: parseFloat(safeGet(beaconRow, 'lat', '0')),
            lng: parseFloat(safeGet(beaconRow, 'lng', '0')),
        };
        if (!newBeaconRecord.uuid) {
            console.log('Missing required data: ', beaconRow);
            continue;
        }
        newBeaconArray.push(newBeaconRecord);
    }
    return { name: planName, beacons: newBeaconArray };
};
