import { ProList } from '@ant-design/pro-components';
import React, { Key, useEffect, useState } from 'react';
import { Button, message, Typography, Empty, Tooltip, Tag, Space, Flex, Divider } from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    ImportOutlined,
    ExportOutlined,
} from '@ant-design/icons';
import {
    AddVenueRuntimeConfigModule,
    DeleteVenueRuntimeConfigModule,
} from 'components/dialogs/HandleProjVenueDialog';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { formatDateAgo } from 'utils/DateUtils';
import { VersionRangeTag } from 'components/VersionRangeTag';
import Markdown from 'react-markdown';
import { ConfigPlaygroundDialog } from 'components/dialogs/ConfigPlaygroundDialog';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import { importRuntimeConfig } from 'apis/VenueApi';
const { Text } = Typography;

function downloadConfigObj(obj: any) {
    const blob = new Blob([JSON.stringify(obj, null, 4)], {
        type: 'application/json',
    });
    // Create a download link
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'venue-config.json';
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

function importConfigFile() {
    return new Promise<any[]>((resolve, reject) => {
        // Create an input element of type file
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.json';
        fileInput.style.display = 'none';
        document.body.appendChild(fileInput);
        fileInput.click();

        // Add an event listener to the file input to handle file selection
        fileInput.addEventListener('change', function (fe: any) {
            const files = fe?.target?.files;
            if (!!files && files.length === 1) {
                const reader = new FileReader();
                reader.onload = function (e: any) {
                    const content = e.target.result;
                    try {
                        const jsonData = JSON.parse(content);
                        const result = convertToRtConfig(jsonData);
                        resolve(result);
                    } catch (error) {
                        console.error('Error parsing JSON file:', error);
                        reject(error);
                    }
                };
                reader.readAsText(files[0]);
            } else {
                reject(null);
            }
        });
    });
}

function convertToRtConfig(data: any) {
    const __assert = (cond: boolean, message?: string) => {
        if (!cond) throw new Error(message ?? 'Assert failed.');
    };
    const mData: any[] = [];
    const __toConfigEl = (obj: any) => {
        const ruleKeyName = ['brand', 'os', 'osVerFrom', 'osVerTo', 'sdkVerFrom', 'sdkVerTo'];
        try {
            __assert(obj?.type === 'RUNTIME');
            __assert(typeof obj?.desc === 'string' && obj.desc.length > 0);
            __assert(typeof obj?.content === 'string' && obj.content.length > 2);
            JSON.parse(obj.content);
            const mRule: any = {};
            if (!!obj?.rules && typeof obj.rules === 'object') {
                for (const k of Object.keys(obj.rules)) {
                    if (ruleKeyName.includes(k)) {
                        mRule[k] = obj.rules[k];
                    }
                }
            }
            mData.push({
                type: 'RUNTIME',
                desc: obj.desc,
                content: obj.content,
                enabled: typeof obj?.enabled === 'boolean' ? obj.enabled : true,
                priority: typeof obj?.priority === 'number' ? obj.priority : 0,
                rules: mRule,
            });
        } catch (ex) {
            /* do nothing */
        }
    };
    if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
            __toConfigEl(data[i]);
        }
    } else {
        __toConfigEl(data);
    }
    return mData;
}

export const RuntimeConfigSection: React.FC<{
    parentId?: string;
    enableSimulate?: boolean;
    isLicense?: boolean;
}> = (props) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { isLoading, venueRtConfig, fetchVenueRtConfig } = useVenueState();
    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly Key[]>([]);
    const [isImporting, setImporting] = useState<boolean>(false);

    useEffect(() => {
        if (!!props.parentId) fetchVenueRtConfig(props.parentId);
    }, [props.parentId]);

    function _handleExport() {
        if (!venueRtConfig?.runtime) return;
        downloadConfigObj(venueRtConfig.runtime);
    }

    function _handleImport() {
        importConfigFile()
            .then((mData: any[]) => {
                if (mData.length > 0) {
                    const mMsg = [`Are you sure to import ${mData.length} runtime config?\n`];
                    mData.forEach((el: any, idx) =>
                        mMsg.push(`${idx + 1}. ${el.desc ?? el?.content ?? 'unknown'}`),
                    );
                    if (confirm(mMsg.join('\n')) === true) {
                        _processImportData(mData);
                    }
                } else {
                    alert(`Cannot import your config file.\nInvalid runtime config format.`);
                }
            })
            .catch(() => {});
    }

    function _processImportData(data: any) {
        if (!props.parentId) return;
        const mParentId = props.parentId;
        setImporting(true);
        importRuntimeConfig(mParentId, data)
            .then(() => {
                messageApi.success('venue config imported.');
                fetchVenueRtConfig(mParentId);
            })
            .finally(() => {
                setImporting(false);
            });
    }

    function _renderCreateBtn() {
        if (!props.parentId) return undefined;
        return (
            <AddVenueRuntimeConfigModule
                key="btn-add-venueRuntimeConfig"
                venueId={props.parentId}
                processingConfig={null}
                isEdit={false}
                trigger={
                    <Button type="primary" icon={<PlusOutlined />}>
                        Add config
                    </Button>
                }
                onSuccess={() => {
                    fetchVenueRtConfig(props.parentId!);
                    message.success('Runtime config created');
                }}
            />
        );
    }

    function _renderSimulateBtn() {
        if (!props.enableSimulate) return undefined;
        return (
            <ConfigPlaygroundDialog
                key={'test-area'}
                parentId={props.parentId}
                isLicense={props.isLicense}
            />
        );
    }

    if (!props.parentId) return <Empty />;
    return (
        <ProList
            search={false}
            rowKey="id"
            headerTitle="Runtime config"
            loading={isLoading || isImporting}
            tooltip={{
                icon: (
                    <ViewDocumentModule
                        trigger={<QuestionCircleOutlined />}
                        docName="sdkRuntimeConfig.md"
                    />
                ),
            }}
            toolBarRender={() => [_renderSimulateBtn(), _renderCreateBtn()]}
            showActions="hover"
            expandable={{ expandedRowKeys, onExpandedRowsChange: setExpandedRowKeys }}
            dataSource={venueRtConfig?.runtime ?? []}
            rowClassName={(record: any) => {
                return !record?.enabled ? 'opacity-05' : '';
            }}
            metas={{
                title: {
                    dataIndex: 'basic',
                    search: false,
                    render: (_, row) => (
                        <Flex vertical style={{ fontWeight: 'normal' }}>
                            <Space style={{ alignItems: 'center', marginBottom: 8 }}>
                                <VersionRangeTag
                                    type={row.rules?.os}
                                    from={row.rules?.osVerFrom}
                                    to={row.rules?.osVerTo}
                                />
                                <VersionRangeTag
                                    type={'SDK'}
                                    from={row.rules?.sdkVerFrom}
                                    to={row.rules?.sdkVerTo}
                                />
                            </Space>
                            <div
                                title={`ID: ${row.id.slice(-3)}`}
                                onClick={() => {
                                    setExpandedRowKeys((x) => {
                                        return x.includes(row.id)
                                            ? x.filter((y) => y !== row.id)
                                            : [...x, row.id];
                                    });
                                }}
                            >
                                <Markdown className="p-no-margin">{row.desc}</Markdown>
                            </div>
                        </Flex>
                    ),
                },
                description: {
                    dataIndex: 'basic',
                    search: false,
                    render: (_, row, index) => (
                        <Typography>
                            <pre>{JSON.stringify(JSON.parse(row.content), null, 4)}</pre>
                            <Typography.Text type="secondary">
                                Priority: {row.priority}; Version: {row.version}
                            </Typography.Text>
                        </Typography>
                    ),
                },
                actions: {
                    render: (text, row, index) => [
                        <Flex key={`conf-ext-gp-${row.id}`} vertical align="end">
                            <Flex gap={10} key={index}>
                                {row.enabled ? undefined : (
                                    <Tag
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        bordered={false}
                                    >
                                        {'DISABLED'}
                                    </Tag>
                                )}
                                <AddVenueRuntimeConfigModule
                                    key="btn-edit-venueRuntimeConfig"
                                    venueId={props.parentId!}
                                    processingConfig={venueRtConfig?.runtime[index]}
                                    isEdit={true}
                                    trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                                    onSuccess={() => {
                                        fetchVenueRtConfig(props.parentId!);
                                        message.success('Runtime config updated');
                                    }}
                                />

                                <DeleteVenueRuntimeConfigModule
                                    key="btn-delete-venueRuntimeConfig"
                                    processingVenueId={props.parentId!}
                                    processingConfig={row}
                                    onSuccess={() => {
                                        fetchVenueRtConfig(props.parentId!);
                                        message.success('Runtime config deleted');
                                    }}
                                    trigger={
                                        <Button danger icon={<DeleteOutlined />}>
                                            Delete
                                        </Button>
                                    }
                                />
                            </Flex>
                            <Tooltip
                                title={moment(new Date(row.updatedAt)).format('DD-MMMM-YYYY HH:mm')}
                            >
                                Last update: {formatDateAgo(row.updatedAt)}
                            </Tooltip>
                        </Flex>,
                    ],
                    search: false,
                },
            }}
            footer={
                <Flex justify="center" align="center">
                    {contextHolder}
                    <Button type="text" size="small" title="Import" onClick={_handleImport}>
                        <Text type="secondary" className="text-08">
                            <ImportOutlined style={{ marginRight: 6 }} /> Import
                        </Text>
                    </Button>
                    <Divider type="vertical" />
                    {venueRtConfig?.runtime ? (
                        <Button type="text" size="small" title="Export" onClick={_handleExport}>
                            <Text type="secondary" className="text-08">
                                <ExportOutlined style={{ marginRight: 6 }} /> Export
                            </Text>
                        </Button>
                    ) : undefined}
                </Flex>
            }
        />
    );
};

export default RuntimeConfigSection;
