import React, { useRef, useState } from 'react';
import { Button, Flex, Form, Radio, Space, Typography } from 'antd';
import {
    FormListActionType,
    ModalForm,
    ProCard,
    ProFormDigit,
    ProFormList,
} from '@ant-design/pro-components';
import { DeleteOutlined } from '@ant-design/icons';
import AnchorPlayground, { AnchorPlaygroundRef } from 'components/AnchorPlayground';
import { IXYLatLngPair, PolygonUtil } from 'utils/PolygonUtil';
import { BaseDialogProps } from './BaseDialogProps.type';
import { MapContainer } from 'react-leaflet';
import { LatLngDto } from 'apis/VenueApi';
import MapTileControl from 'components/maps/MapTileControl';
import { useVenueState } from 'providers/VenueProvider';
import MarkerDrawingLayer, { MarkerDrawingLayerRef } from 'components/maps/MarkerDrawingLayer';

export interface IAnchor extends Partial<IXYLatLngPair> {
    id: number;
}

export interface AnchorInputModuleProps extends BaseDialogProps {
    initCenter?: LatLngDto;
    imageUrl: string;
}

const AnchorInputModule: React.FC<AnchorInputModuleProps> = (props) => {
    const [formRef] = Form.useForm<{ anchor: IAnchor[] }>();
    const actionRef = useRef<FormListActionType>();
    const playgroundRef = useRef<AnchorPlaygroundRef>();
    const mapMarkerRef = useRef<MarkerDrawingLayerRef>();
    const anchorCount = useRef<number>(0);
    const editingField = useRef<string>('');
    const [isEnoughPt, setIsEnoughPt] = useState<boolean>(false);
    const [mapMode, setMapMode] = useState<string>('l');
    const { venue } = useVenueState();

    const _handleSubmit = (anchors: IAnchor[]): boolean => {
        try {
            const isValid = anchors.every(
                (anchor) =>
                    typeof anchor.x === 'number' &&
                    typeof anchor.y === 'number' &&
                    typeof anchor.lat === 'number' &&
                    typeof anchor.lng === 'number',
            );
            if (isValid) {
                const conf = playgroundRef.current?.getConfig();
                const result = PolygonUtil.fromAnchors(anchors as any[], {
                    width: conf!.playground.imgSize[0],
                    height: conf!.playground.imgSize[1],
                });
                if (props.onSuccess) props.onSuccess(result);
            }
            return isValid;
        } catch (ex) {
            console.error('Failed to align with anchors:', ex);
            return false;
        }
    };

    const _handlePasteValue = (evt: React.ClipboardEvent<HTMLInputElement>) => {
        try {
            const pastedValue = evt.clipboardData.getData('text');
            const fieldKey = (evt.target as any)?.id.split('_');
            if (fieldKey.length !== 3) throw new Error('Cannot paste key from input id.');
            fieldKey[1] = Number(fieldKey[1]);

            // Decode pasted value and update form
            const temp: Record<string, any> = { x: undefined, y: undefined };
            const strValuePart = pastedValue.split(/[\s|,]/);
            if (strValuePart.length > 1 && pastedValue.includes('{')) {
                const mJsonObj = JSON.parse(pastedValue);
                Object.entries(mJsonObj).forEach(([key, value]) => {
                    if (
                        ['x', 'y', 'lat', 'lng'].includes(key) &&
                        (typeof value === 'number' || typeof value === 'string')
                    ) {
                        formRef.setFieldValue(
                            [fieldKey[0], fieldKey[1], key as any],
                            Number(value),
                        );
                        temp[key] = Number(value);
                    }
                });
            } else if (strValuePart.length > 1) {
                const numValuePart = strValuePart
                    .filter((part) => Boolean(part) && !isNaN(Number(part)))
                    .map(Number);

                if (numValuePart.length == 2 && fieldKey[2] === 'x') {
                    formRef.setFieldValue([fieldKey[0], fieldKey[1], 'x'], numValuePart[0]);
                    formRef.setFieldValue([fieldKey[0], fieldKey[1], 'y'], numValuePart[1]);
                    temp.x = numValuePart[0];
                    temp.y = numValuePart[1];
                } else if (numValuePart.length == 2 && fieldKey[2] === 'lat') {
                    formRef.setFieldValue([fieldKey[0], fieldKey[1], 'lat'], numValuePart[0]);
                    formRef.setFieldValue([fieldKey[0], fieldKey[1], 'lng'], numValuePart[1]);
                    temp.lat = numValuePart[0];
                    temp.lng = numValuePart[1];
                }
            } else if (strValuePart.length === 1) {
                const trimmedValue = pastedValue.trim();
                if (!isNaN(Number(trimmedValue))) {
                    formRef.setFieldValue(fieldKey, Number(trimmedValue));
                }
            }

            // Update canvas
            if (!isNaN(temp.x) && !isNaN(temp.y)) {
                const formValues = formRef.getFieldsValue();
                const mId = formValues.anchor[fieldKey[1]]?.id;
                if (mId !== undefined) playgroundRef.current?.update(mId, { x: temp.x, y: temp.y });
            }

            // Update map marker
            if (!isNaN(temp.lat) && !isNaN(temp.lng)) {
                const formValues = formRef.getFieldsValue();
                const mId = formValues.anchor[fieldKey[1]]?.id;
                if (mId !== undefined)
                    mapMarkerRef.current?.updateById(mId, { lat: temp.lat, lng: temp.lng });
            }
            evt.preventDefault();
        } catch (ex) {
            return;
        }
    };

    const _handleDbClickAdd = (evt: React.MouseEvent<HTMLInputElement>) => {
        const fieldKey = (evt.target as any)?.id.split('_');
        if (fieldKey.length !== 3) throw new Error('Cannot paste key from input id.');
        fieldKey[1] = Number(fieldKey[1]);
        const mId = formRef.getFieldValue([fieldKey[0], fieldKey[1], 'id']);
        const mLat = formRef.getFieldValue([fieldKey[0], fieldKey[1], 'lat']);
        const mLng = formRef.getFieldValue([fieldKey[0], fieldKey[1], 'lng']);
        if (isNaN(mLat) && isNaN(mLng) && mapMarkerRef.current) {
            mapMarkerRef.current.addMarker(mId);
        }
    }

    const _renderPanelForm = () => {
        return (
            <ProFormList
                name="anchor"
                creatorButtonProps={{
                    creatorButtonText: 'Add lock point',
                }}
                copyIconProps={false}
                actionRef={actionRef}
                itemRender={({ listDom, action }, { index, record }) => (
                    <ProCard
                        bordered
                        headerBordered
                        style={{ marginBlockEnd: 8 }}
                        headStyle={{
                            height: 30,
                            padding: 8,
                            backgroundColor: '#f7f7f7',
                        }}
                        title={`Anchor ${record.id}`}
                        data-anchor-id={record.id}
                        extra={[
                            <Button
                                key={`del-${record.id}`}
                                icon={<DeleteOutlined />}
                                size="small"
                                type="text"
                                title="Remove anchor"
                                onClick={() => {
                                    if (playgroundRef.current)
                                        playgroundRef.current.remove(record.id);
                                    if (actionRef.current) actionRef.current?.remove(index);
                                    if (mapMarkerRef.current)
                                        mapMarkerRef.current?.removeById(record.id);
                                    setIsEnoughPt(formRef.getFieldsValue().anchor.length >= 2);
                                }}
                            />,
                        ]}
                        bodyStyle={{ paddingBlockEnd: 0 }}
                        size="small"
                        className="tiny-row"
                    >
                        {listDom}
                    </ProCard>
                )}
                creatorRecord={() => ({ id: ++anchorCount.current })}
                onAfterAdd={(record, index) => {
                    playgroundRef.current?.addMarker(record.id);
                    mapMarkerRef.current?.addMarker(record.id);
                    setIsEnoughPt(formRef.getFieldsValue().anchor.length >= 2);
                }}
            >
                <ProFormDigit name="id" hidden />
                <Space.Compact className="w100">
                    <div className="input-group">
                        <label htmlFor="lat">Latitude</label>
                        <ProFormDigit
                            name="lat"
                            rules={[{ required: true }]}
                            placeholder="Latitude"
                            fieldProps={{
                                onPaste: _handlePasteValue,
                                onDoubleClick: _handleDbClickAdd,
                            }}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="lng">Longitude</label>
                        <ProFormDigit
                            name="lng"
                            rules={[{ required: true }]}
                            placeholder="Longitude"
                        />
                    </div>
                </Space.Compact>
                <Space.Compact className="w100">
                    <div className="input-group">
                        <label htmlFor="x">X</label>
                        <ProFormDigit
                            name="x"
                            rules={[{ required: true }]}
                            placeholder="X  (px)"
                            fieldProps={{ onPaste: _handlePasteValue }}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="y">Y</label>
                        <ProFormDigit name="y" rules={[{ required: true }]} placeholder="Y (px)" />
                    </div>
                </Space.Compact>
            </ProFormList>
        );
    };

    const _renderPlayground = () => {
        return (
            <AnchorPlayground
                ref={playgroundRef as any}
                visible={mapMode === 'l'}
                imageUrl={props.imageUrl}
                onChange={(id, result) => {
                    if (!!formRef) {
                        const val = formRef.getFieldsValue();
                        const mIndex = val.anchor.findIndex((el) => el.id === id);
                        if (mIndex > -1) {
                            formRef.setFieldValue(['anchor', mIndex, 'x'], Number(result.x));
                            formRef.setFieldValue(['anchor', mIndex, 'y'], Number(result.y));
                        }
                    }
                }}
                onSelect={(id) => {
                    const element = document.querySelector(`[data-anchor-id='${id}']`);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    }
                }}
            />
        );
    };

    const _renderMap = () => {
        if (!venue || mapMode !== 'g') return;
        const mAnchors = formRef.getFieldsValue().anchor ?? [];
        return (
            <MapContainer
                center={[venue!.center.lat, venue!.center.lng]}
                zoomSnap={0.7}
                zoom={18}
                scrollWheelZoom={true}
                zoomControl={false}
                style={{ position: 'relative', height: '100%', flex: 1 }}
            >
                <MapTileControl visible />
                <MarkerDrawingLayer
                    ref={mapMarkerRef as any}
                    initMarkers={mAnchors
                        .filter((e) => Boolean(e.lat) && Boolean(e.lng))
                        .map((e) => ({ id: e.id, lat: Number(e.lat), lng: Number(e.lng) }))}
                    onChange={(result) => {
                        if (!!formRef) {
                            const val = formRef.getFieldsValue();
                            const mIndex = val.anchor.findIndex((el) => el.id === result.id);
                            if (mIndex > -1) {
                                formRef.setFieldValue(
                                    ['anchor', mIndex, 'lat'],
                                    Number(result.lat),
                                );
                                formRef.setFieldValue(
                                    ['anchor', mIndex, 'lng'],
                                    Number(result.lng),
                                );
                            }
                        }
                    }}
                    onClick={({ id }) => {
                        const element = document.querySelector(`[data-anchor-id='${id}']`);
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                    }}
                />
            </MapContainer>
        );
    };

    const _buildModuleBody = () => {
        return (
            <Flex style={{ overflow: 'hidden' }} className="h100">
                <Flex
                    className="venue-panel"
                    style={{
                        width: '30%',
                        minWidth: 300,
                        zIndex: 2,
                    }}
                    vertical
                >
                    <Typography.Text style={{ padding: '6px 6px 6px 0' }}>
                        You need at least 2 lock points to calculate floor plan alignment.
                    </Typography.Text>
                    <Space
                        style={{ flex: 1, overflow: 'auto', paddingRight: 12 }}
                        direction="vertical"
                    >
                        {_renderPanelForm()}
                    </Space>
                </Flex>
                <Flex className="w100" style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: 16, left: 12, zIndex: 500, minWidth: 200 }}>
                        <Radio.Group
                            value={mapMode}
                            buttonStyle="solid"
                            onChange={(e) => setMapMode(e.target.value)}
                            block
                        >
                            <Radio.Button value="l">Floor plan</Radio.Button>
                            <Radio.Button value="g">Base map</Radio.Button>
                        </Radio.Group>
                    </div>
                    {_renderMap()}
                    {_renderPlayground()}
                </Flex>
            </Flex>
        );
    };

    return (
        <ModalForm<{ anchor: IAnchor[] }>
            title="Align with anchors"
            trigger={props.trigger}
            form={formRef as any}
            width="98vw"
            modalProps={{
                style: { top: 12 },
                destroyOnClose: true,
                maskClosable: false,
                keyboard: false,
                zIndex: 1210,
            }}
            submitter={{
                searchConfig: { submitText: 'Preview' },
                submitButtonProps: { disabled: !isEnoughPt },
                resetButtonProps: false,
            }}
            onFinish={async (values) => {
                return _handleSubmit(values.anchor);
            }}
            onFieldsChange={(changedFields, allFields) => {
                if (
                    !formRef ||
                    !playgroundRef.current ||
                    changedFields.length !== 1 ||
                    changedFields[0].name?.length !== 3 ||
                    !changedFields[0].validated
                ) {
                    return;
                }
                const target = changedFields[0];
                // Handle human input
                if (['x', 'y'].includes(target.name[2])) {
                    const key = `${target.name.join(',')}-${target.value}`;
                    if (key !== editingField.current) {
                        const mValues = formRef.getFieldsValue();
                        const mId = mValues.anchor[target.name[1]].id;
                        editingField.current = `${target.name.join(',')}-${target.value}`;
                        const mX = Number(mValues.anchor[target.name[1]].x);
                        const mY = Number(mValues.anchor[target.name[1]].y);
                        if (!isNaN(mX) && !isNaN(mY))
                            playgroundRef.current.update(mId, { x: mX, y: mY });
                    }
                } else if (['lat', 'lng'].includes(target.name[2])) {
                    const key = `${target.name.join(',')}-${target.value}`;
                    if (key !== editingField.current) {
                        const mValues = formRef.getFieldsValue();
                        const mId = mValues.anchor[target.name[1]].id;
                        editingField.current = `${target.name.join(',')}-${target.value}`;
                        const mLat = Number(mValues.anchor[target.name[1]].lat);
                        const mLng = Number(mValues.anchor[target.name[1]].lng);
                        if (!isNaN(mLat) && !isNaN(mLng))
                            mapMarkerRef.current?.updateById(mId, { lat: mLat, lng: mLng });
                        else mapMarkerRef.current?.removeById(mId);
                    }
                }
            }}
            onReset={() => {
                if (!!playgroundRef.current) playgroundRef.current.remove();
                if (!!mapMarkerRef.current) mapMarkerRef.current.resetAll();
                setIsEnoughPt(false);
                setMapMode('l');
            }}
            onOpenChange={(flag) => {
                if (!flag && !!formRef) {
                    formRef.resetFields();
                    anchorCount.current = 0;
                    editingField.current = '';
                    setIsEnoughPt(false);
                    setMapMode('l');
                }
            }}
        >
            <div style={{ height: `${window.innerHeight - 152}px` }}>{_buildModuleBody()}</div>
        </ModalForm>
    );
};

export default React.memo(AnchorInputModule, (prev, curr) => {
    return prev.imageUrl === curr.imageUrl;
});
