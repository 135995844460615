import React, { memo, useRef, useEffect, useImperativeHandle } from 'react';
import mapboxgl, { EventData } from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'styles/map-style.css';
import { useMapBox } from './MapBoxContainer';
import { Feature, GeoJsonProperties } from 'geojson';
import { Point, Polygon } from '@turf/turf';
import iconPinHole from '../../assets/pin_hole.png';
import { inside, distance, bearing, destination } from '@turf/turf';

interface IMarkerEntity {
    id: string | number;
    label?: string;
    lat: number;
    lng: number;
}

interface IPointOffset {
    id: string | number;
    bearing: number;
    distance: number;
}

export interface MapBoxMarkerLayerProps {
    editable?: boolean;
    onClick?: (item: IMarkerEntity) => void;
    onHover?: (id: string | number | undefined) => void;
    onChanged?: (item: IMarkerEntity) => void;
}

export type MapBoxMarkerLayerRef = {
    resetAll: (data?: IMarkerEntity[]) => void;
    addMarker: (id: string | number, label?: string) => void;
    removeById: (ids: string | number | string[] | number[]) => void;
    getMarkers: () => IMarkerEntity[];
    setHoverItem: (id: string | number | undefined) => void;
    selectItems: (area?: Feature<Polygon, GeoJsonProperties>) => void;
    flyTo: (id: string | number) => void;
};
const markIcon = document.createElement('img') as HTMLImageElement;
markIcon.src = iconPinHole;

const MapBoxMarkerLayer = React.forwardRef<MapBoxMarkerLayerRef, MapBoxMarkerLayerProps>(
    (props, ref) => {
        const layerLoaded = useRef<boolean>(false);
        const allowHover = useRef<boolean | undefined>(undefined);
        const hoverIdRef = useRef<string | number | undefined>(undefined);
        const movingPtRef = useRef<IPointOffset | undefined>(undefined);
        const movedRef = useRef<boolean>(false);
        const dataRef = useRef<Feature<Point, GeoJsonProperties>[]>([]);
        const selectedAreaRef = useRef<Feature<Polygon, GeoJsonProperties>>();
        const { map } = useMapBox();

        useEffect(() => {
            _initMapLayers();
        }, [map]);

        const _initMapLayers = () => {
            if (!map || layerLoaded.current == true) return;
            map.addImage('blue-marker', markIcon, { sdf: true });

            // Add source and layer for markers
            map.addSource('b-marker-source', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features: dataRef.current,
                },
            });

            map.addLayer({
                id: 'b-marker-layer',
                type: 'symbol',
                source: 'b-marker-source',
                layout: {
                    'icon-image': 'blue-marker',
                    'icon-allow-overlap': true,
                    'icon-size': 1,
                    'icon-anchor': 'bottom',
                    'icon-ignore-placement': true,
                },
                paint: {
                    'icon-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        '#F71B7E',
                        ['boolean', ['feature-state', 'selected'], false],
                        '#FBB03C',
                        '#1B94F7',
                    ],
                    'icon-halo-color': 'white',
                    'icon-halo-width': 5,
                },
            });

            // Add a layer for the labels
            map.addLayer({
                id: 'b-label-layer',
                type: 'symbol',
                source: 'b-marker-source',
                layout: {
                    'text-field': ['get', 'label'], // Get the ID from properties
                    'text-size': 12,
                    'text-anchor': 'top', // Position the text above the marker
                    'text-offset': [0, 0], // Offset the text slightly below the marker
                },
            });

            map.on('click', 'b-marker-layer', (e) => {
                if (props.onClick) props.onClick(buildMarkerEntity(e.features?.[0]));
            });

            if (props.editable) {
                map.on('mousedown', 'b-marker-layer', (e) => {
                    // Prevent the default map drag behavior.
                    e.preventDefault();
                    movedRef.current = false;
                    movingPtRef.current = {
                        id: e.features?.[0].properties?.id,
                        bearing: 0,
                        distance: 0,
                    };
                    const recordPos = (e.features?.[0].geometry as any).coordinates;
                    const mousePod = [e.lngLat.lng, e.lngLat.lat];
                    setTimeout(() => {
                        if (movingPtRef.current !== undefined) {
                            movingPtRef.current.distance = distance(mousePod, recordPos);
                            movingPtRef.current.bearing = bearing(mousePod, recordPos);
                            map.on('mousemove', onMarkerMove);
                        }
                    }, 200);
                    map.once('mouseup', onMarkerUp);
                });
            }
            checkHoverFeature();
            layerLoaded.current = true;
        };

        const _notifySourceUpdate = () => {
            if (!map) return;
            (map.getSource('b-marker-source') as mapboxgl.GeoJSONSource).setData({
                type: 'FeatureCollection',
                features: dataRef.current,
            });
            map.moveLayer('b-marker-layer', 'b-label-layer');
        };

        const onMarkerMove = (evt: EventData) => {
            if (!map) return;
            const idx = dataRef.current.findIndex((el) => el.id === movingPtRef.current?.id);
            if (idx > -1) {
                let coords = [evt.lngLat.lng, evt.lngLat.lat];
                if (movingPtRef.current?.id) {
                    const dest = destination(
                        coords,
                        movingPtRef.current.distance,
                        movingPtRef.current.bearing,
                    );
                    coords = dest.geometry.coordinates;
                }
                dataRef.current[idx].geometry.coordinates = coords;
                movedRef.current = true;
                _notifySourceUpdate();
            }
        };

        const onMarkerUp = (evt: EventData) => {
            if (!map) return;
            // Unbind mouse/touch events
            map.off('mousemove', onMarkerMove);
            const idx = dataRef.current.findIndex((el) => el.id === movingPtRef.current?.id);
            if (idx > -1 && movedRef.current) {
                let coords = [evt.lngLat.lng, evt.lngLat.lat];
                if (movingPtRef.current?.id) {
                    const dest = destination(
                        coords,
                        movingPtRef.current.distance,
                        movingPtRef.current.bearing,
                    );
                    coords = dest.geometry.coordinates;
                }
                dataRef.current[idx].geometry.coordinates = coords;
                if (props.onChanged) props.onChanged(buildMarkerEntity(dataRef.current[idx]));
                _updateSelection();
            }
            movedRef.current = false;
            movingPtRef.current = undefined;
        };

        const onMarkerEnter = (e: EventData) => {
            if (!map || movingPtRef.current !== undefined) return;
            map.getCanvas().style.cursor = 'move';
            if (e.features && e.features.length > 0) {
                const currId = e.features?.[0].properties?.id;
                if (!!hoverIdRef.current && hoverIdRef.current !== currId) {
                    map.setFeatureState(
                        { source: 'b-marker-source', id: hoverIdRef.current },
                        { hover: false },
                    );
                }
                hoverIdRef.current = currId;
                map.setFeatureState({ source: 'b-marker-source', id: currId }, { hover: true });
                if (props.onHover) props.onHover(e.features?.[0].id ?? currId);
            }
        };

        const onMarkerLeave = (e: EventData) => {
            if (!map || movingPtRef.current !== undefined) return;
            map.getCanvas().style.cursor = '';
            if (hoverIdRef.current !== undefined) {
                map.setFeatureState(
                    { source: 'b-marker-source', id: hoverIdRef.current },
                    { hover: false },
                );
                if (props.onHover) props.onHover(undefined);
            }
            hoverIdRef.current = undefined;
        };

        const checkHoverFeature = () => {
            if (!map) return;
            if (dataRef.current.length > 1000 || allowHover.current === true) {
                map.off('mouseenter', 'b-marker-layer', onMarkerEnter);
                map.off('mouseleave', 'b-marker-layer', onMarkerLeave);
            } else {
                // Mouse enter event
                map.on('mouseenter', 'b-marker-layer', onMarkerEnter);
                // Mouse leave event
                map.on('mouseleave', 'b-marker-layer', onMarkerLeave);
            }
        };

        const buildDataGeojson = (data: IMarkerEntity): Feature<Point, GeoJsonProperties> => {
            return {
                type: 'Feature',
                id: data.id,
                geometry: {
                    type: 'Point',
                    coordinates: [data.lng, data.lat],
                },
                properties: {
                    id: data.id,
                    label: data.label,
                },
            };
        };

        const buildMarkerEntity = (e: any) => {
            return {
                id: e.id ?? e.properties?.id!,
                label: e.properties?.label,
                lat: e.geometry.coordinates[1],
                lng: e.geometry.coordinates[0],
            };
        };

        const handleAddMarker = (id: string | number, label?: string) => {
            if (!map) return;
            // Create temp marker
            const mapCenter = map.getCenter();
            const tempMarker = new mapboxgl.Marker()
                .setLngLat([mapCenter.lng, mapCenter.lat])
                .addTo(map);

            // Add temp marker move listener
            const moveFunc = (evt: EventData) => {
                const coords = evt.lngLat;
                tempMarker.setLngLat([coords.lng, coords.lat]);
            };
            map.on('mousemove', moveFunc);
            map.once('click', (e) => {
                map.off('mousemove', moveFunc);
                const coords = tempMarker.getLngLat();
                tempMarker.remove();
                const entity = {
                    id,
                    label,
                    lat: coords.lat,
                    lng: coords.lng,
                };
                dataRef.current.push(buildDataGeojson(entity));
                if (props.onChanged) props.onChanged(entity);
                _notifySourceUpdate();
            });
        };

        const handleHover = (id: string | number | undefined) => {
            if (!map || movingPtRef.current !== undefined) return;
            if (hoverIdRef.current !== undefined) {
                map.setFeatureState(
                    { source: 'b-marker-source', id: hoverIdRef.current },
                    { hover: false },
                );
            }
            hoverIdRef.current = id;
            if (id !== undefined) {
                map.setFeatureState(
                    { source: 'b-marker-source', id: hoverIdRef.current },
                    { hover: true },
                );
            }
        };

        const _updateSelection = () => {
            if (!map) return;
            const area = selectedAreaRef.current;
            for (let i = 0; i < dataRef.current.length; i++) {
                if (!!area && inside(dataRef.current[i], area)) {
                    map.setFeatureState(
                        { source: 'b-marker-source', id: dataRef.current[i].properties!.id },
                        { selected: true },
                    );
                } else {
                    map.setFeatureState(
                        { source: 'b-marker-source', id: dataRef.current[i].properties!.id },
                        { selected: false },
                    );
                }
            }
        };

        useImperativeHandle(ref, () => ({
            resetAll(data?: IMarkerEntity[]) {
                const sourceData = (data ?? []).map(buildDataGeojson);
                dataRef.current = sourceData;
                if (layerLoaded.current === true) {
                    _notifySourceUpdate();
                    checkHoverFeature;
                }
            },
            addMarker(id: string | number, label?: string) {
                const idx = dataRef.current.findIndex((el) => el.id === id);
                if (idx > -1) {
                    dataRef.current = dataRef.current.filter((el) => el.id !== id);
                    _notifySourceUpdate();
                }
                handleAddMarker(id, label);
            },
            removeById(id: string | number | string[] | number[]) {
                if (Array.isArray(id)) {
                    dataRef.current = dataRef.current.filter(
                        (el) => !(id as any[]).includes(el.id),
                    );
                } else {
                    dataRef.current = dataRef.current.filter((el) => el.id !== id);
                }
                _notifySourceUpdate();
            },
            getMarkers() {
                return dataRef.current.map((el) => ({
                    id: el.id || el.properties!.id,
                    label: el.properties?.label,
                    lat: el.geometry.coordinates[1],
                    lng: el.geometry.coordinates[0],
                }));
            },
            setHoverItem(id: string | number | undefined) {
                handleHover(id);
            },
            flyTo(id: string | number) {
                const marker = dataRef.current.find((el) => el.id === id);
                if (marker && map) {
                    const pos = marker.geometry.coordinates;
                    map.flyTo({
                        center: [pos[0], pos[1]],
                        zoom: 20,
                        essential: true,
                    });
                }
            },
            selectItems(area?: Feature<Polygon, GeoJsonProperties>) {
                if (!map) return;
                selectedAreaRef.current = area;
                _updateSelection();
            },
        }));

        return null;
    },
);

export default memo(MapBoxMarkerLayer, (prev, curr) => prev.editable === curr.editable);
