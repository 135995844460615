import * as turf from '@turf/turf';
import { AlignmentDto, LatLngDto } from 'apis/VenueApi';
import { Direction } from 'components/maps/PositionOffsetPanel';
import { ImgSizeDto, PolygonUtil } from 'utils/PolygonUtil';

export interface AlignResult {
    center: LatLngDto;
    angle: number;
    scale: number;
    corners: [number, number][];
    sizeInMeters: { width: number; height: number };
    size: ImgSizeDto;
}
export const DirectionAngle = {
    N: 0,
    E: 90,
    S: 180,
    W: 270,
};

export class AlignmentUtil {
    private imgSize?: ImgSizeDto = undefined;
    constructor() {}
    async init(options?: { imageUrl?: string }): Promise<boolean> {
        if (options?.imageUrl) {
            try {
                const mImgSize = await this._getImageSize(options.imageUrl);
                this.imgSize = mImgSize;
                return Promise.resolve(true);
            } catch (ex) {
                return Promise.reject(ex);
            }
        } else {
            this.imgSize = undefined;
            return Promise.resolve(true);
        }
    }

    private _getImageSize(url: string): Promise<ImgSizeDto> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function () {
                const imageSize = PolygonUtil.getRatio(img.width, img.height);
                resolve(imageSize);
            };
            img.onerror = function () {
                reject(new Error('Failed to load image.'));
            };
            img.src = url;
        });
    }

    private _calculateBaseBbox(
        mapCenter: LatLngDto,
        mapImgSize: ImgSizeDto,
        scaleFactor: number = 1,
    ) {
        // Define the center point
        const center = turf.point([mapCenter.lng, mapCenter.lat]);

        // Calculate the coordinates for the polygon (Assume 1 px = 0.1 meter)
        const halfWidth = (mapImgSize.width / 2) * 0.1 * scaleFactor;
        const halfHeight = (mapImgSize.height / 2) * 0.1 * scaleFactor;

        // Calculate center top, right, bottom and left as bounty box
        const boxTop = turf.destination(center, halfHeight, 0, { units: 'meters' });
        const boxRight = turf.destination(center, halfWidth, 90, { units: 'meters' });
        const boxBottom = turf.destination(center, halfHeight, 180, { units: 'meters' });
        const boxLeft = turf.destination(center, halfWidth, 270, { units: 'meters' });

        const bountyBox = turf.bbox(turf.featureCollection([boxTop, boxRight, boxBottom, boxLeft]));
        const _basePolygon = turf.bboxPolygon(bountyBox);
        return _basePolygon;
    }

    static offsetPoint(center: LatLngDto, direction: Direction, meter: number): LatLngDto {
        const directionDeg = DirectionAngle[direction];
        const point = turf.point([center.lng, center.lat]);
        const translatedPoint = turf.transformTranslate(point, meter, directionDeg, {
            units: 'meters',
        });
        return {
            lat: translatedPoint.geometry.coordinates[1],
            lng: translatedPoint.geometry.coordinates[0],
        };
    }

    static getCoordinates(inputPolygon: any): [number, number][] {
        /**
         * 3---2       0---1
         * | X |  ===> |   |
         * 0---1       3---2
         * return [lat, lng]
         */
        const coor = inputPolygon.geometry.coordinates[0];
        return [
            [coor[3][1], coor[3][0]],
            [coor[2][1], coor[2][0]],
            [coor[1][1], coor[1][0]],
            [coor[0][1], coor[0][0]],
            [coor[3][1], coor[3][0]],
        ];
    }

    fromCenterScaleAngle(
        center: LatLngDto,
        scale: number = 100,
        angle: number = 0,
    ): AlignResult | undefined {
        if (!this.imgSize) throw new Error('Image is not loaded.');
        const baseBboxPolygon = this._calculateBaseBbox(center, this.imgSize);
        const centerPoint = turf.center(baseBboxPolygon);

        // Scale the bbox
        const scalePercent = scale; // value from 0 to N; 100 = no resize
        const rotateAngle = angle;
        const cornerDistance = turf.distance(
            centerPoint,
            baseBboxPolygon.geometry.coordinates[0][3],
        );
        // Calculate the scaled distance based on the scale factor (%)
        const scaledDistance = cornerDistance * (scalePercent / 100);
        const scalingRatio = scaledDistance / cornerDistance;

        const _centerDto = {
            lat: centerPoint.geometry.coordinates[1],
            lng: centerPoint.geometry.coordinates[0],
        };
        // Create a buffer around the bbox polygon
        const scaledBboxPolygon = this._calculateBaseBbox(_centerDto, this.imgSize, scalingRatio);

        // Rotate the polygon
        const rotatedPolygon = turf.transformRotate(scaledBboxPolygon, rotateAngle, {
            pivot: centerPoint,
        });

        // Get corners and scaled size
        const mCorners = AlignmentUtil.getCoordinates(rotatedPolygon);
        const mWidth = turf.distance(
            [mCorners[0][1], mCorners[0][0]],
            [mCorners[1][1], mCorners[1][0]],
            { units: 'meters' },
        );
        const mHeight = turf.distance(
            [mCorners[1][1], mCorners[1][0]],
            [mCorners[2][1], mCorners[2][0]],
            { units: 'meters' },
        );
        const result: AlignResult = {
            center: center,
            corners: mCorners,
            angle: rotateAngle,
            scale: scalePercent,
            size: this.imgSize ?? { width: 0, height: 0, angle: 0, aspectRatio: 0 },
            sizeInMeters: { width: mWidth, height: mHeight },
        };
        return result;
    }

    fromCorners(inputPolygon: turf.Feature<turf.Polygon>) {
        if (!this.imgSize) throw new Error('Image is not loaded.');
        if (!inputPolygon || inputPolygon.geometry.coordinates.length != 1) {
            throw new Error('positions array less then 4 item');
        }

        const inputCenter = turf.center(inputPolygon);

        const centerDto: LatLngDto = {
            lat: inputCenter.geometry.coordinates[1],
            lng: inputCenter.geometry.coordinates[0],
        };
        const _basePolygon = this._calculateBaseBbox(centerDto, this.imgSize);

        // Calculate the angle between the centroids
        // Define the top right coordinates of the points
        const pointA = turf.point(inputPolygon.geometry.coordinates[0][0]);
        const pointB = turf.point(_basePolygon.geometry.coordinates[0][3]);
        // Calculate the vectors of OA and convert into angle (offset center top as 0 deg)
        const bearing = turf.bearing(inputCenter, pointA) + this.imgSize.angle / 2;
        const angle = turf.bearingToAzimuth(bearing);

        // Calculate the scale factor by dividing the distance of OA and OB:
        const distanceOA = turf.distance(inputCenter, pointA);
        const distanceOB = turf.distance(inputCenter, pointB);

        // Calculate the scale factor based on the buffer distance
        // 100% = no resize;
        const scaleOffset = distanceOA - distanceOB;
        const scale = 100 * (1 + scaleOffset / distanceOB);

        // Get corners polygon array
        const mCorners = inputPolygon.geometry.coordinates[0];
        const shiftedCorners: [number, number][] = [
            [mCorners[0][1], mCorners[0][0]], // top left (lat, lng)
            [mCorners[1][1], mCorners[1][0]], // bottom left
            [mCorners[2][1], mCorners[2][0]], // bottom right
            [mCorners[3][1], mCorners[3][0]], // top right
            [mCorners[0][1], mCorners[0][0]],
        ];

        // Calculate true size
        const mWidth = turf.distance(mCorners[0], mCorners[1], { units: 'meters' });
        const mHeight = turf.distance(mCorners[1], mCorners[2], { units: 'meters' });

        // Prepare result
        const mResult: AlignResult = {
            center: centerDto,
            corners: shiftedCorners,
            angle: angle,
            scale,
            size: this.imgSize ?? { width: 0, height: 0, angle: 0, aspectRatio: 0 },
            sizeInMeters: { width: mWidth, height: mHeight },
        };

        console.log('Calculated result: ', mResult);
        return mResult;
    }
}
