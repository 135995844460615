import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import {
    LayerName,
    SourceName,
    buildPolyline,
    getBlueDotAccuracyLayer,
    getGeoJson,
} from 'utils/geojson-builder';
import { Feature, FeatureCollection } from 'geojson';

export const HK_CENTER = { lat: 22.3193, lng: 114.1694 };
export const DEFAULT_ZOOM_LV = 8;
export const CONTROL_POSITION_CLASSES = {
    bottomleft: 'leaflet-bottom leaflet-left',
    bottomright: 'leaflet-bottom leaflet-right',
    topleft: 'leaflet-top leaflet-left',
    topright: 'leaflet-top leaflet-right',
};

export type IBlueDotLocation = {
    lat?: number;
    lng?: number;
    accuracy?: number;
    direction?: number;
};

export default class BaseMapEngine {
    private initialized: boolean = false;
    protected mapLoaded: boolean = false;
    protected mapView!: mapboxgl.Map;
    private zoomLevel: number = DEFAULT_ZOOM_LV;
    private blueDotMarker?: mapboxgl.Marker;
    private blueDotDirectionMarker?: mapboxgl.Marker;
    private markerList: mapboxgl.Marker[] = [];
    private _centerMarker: mapboxgl.Marker = new mapboxgl.Marker();

    constructor() {
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_KEY!;
    }

    /**
     * Init mapbox and draw map
     * @param {string} elementId - HTML element id
     */
    init(elementId: string, lightStyle: boolean = true, center?: mapboxgl.LngLatLike) {
        this.initAsync(elementId, lightStyle, center).then(() => {});
    }

    initAsync(elementId: string, lightStyle: boolean = true, center?: mapboxgl.LngLatLike) {
        return new Promise((resolve, reject) => {
            if (this.initialized) {
                this._resume(elementId);
                return resolve(true);
            }
            // Create map view instance
            this.mapView = new mapboxgl.Map({
                container: elementId,
                style: false
                    ? 'mapbox://styles/mapbox/light-v11'
                    : 'mapbox://styles/mapbox/streets-v12',
                center: center ?? HK_CENTER, // starting position [lng, lat]
                zoom: this.zoomLevel,
                pitchWithRotate: false,
                touchPitch: false,
                dragRotate: false,
                touchZoomRotate: false,
            });
            this.initialized = true;

            this.mapView.on('load', () => {
                const waiting = () => {
                    if (!this.mapView.isStyleLoaded()) {
                        console.log(`[map-engine] style is not ready`);
                        setTimeout(waiting, 50);
                    } else {
                        console.log(`[map-engine] map loaded`);
                        this.mapLoaded = true;
                        return resolve(true);
                    }
                };
                waiting();
            });
        });
    }

    resize() {
        if (!this.mapView || !this.mapLoaded) return;
        this.mapView.resize();
    }
    isReady() {
        return this.mapView && this.mapLoaded;
    }
    private _resume(elementId: string) {
        const mapContainer = document.getElementById(elementId);
        if (this.mapView && this.initialized && mapContainer) {
            mapContainer.replaceWith(this.mapView.getContainer());
            this.mapView.resize();
        }
    }

    protected _addMarker(point: mapboxgl.LngLatLike, msg?: string) {
        const mMarker = new mapboxgl.Marker().setLngLat(point).addTo(this.mapView);
        this.markerList.push(mMarker);
    }
    protected _removeAllMarkers() {
        while (this.markerList.length > 0) {
            this.markerList.pop()?.remove();
        }
    }

    protected _setPolyline(
        name: string,
        coordinates?: number[][],
        paint?: mapboxgl.LinePaint,
        moveCamera?: boolean,
    ) {
        const mSourceName = `r-${name}-data`;
        const mLayerName = `r-${name}-layer`;
        const mSource = this.mapView.getSource(mSourceName);
        if (mSource) {
            if (coordinates == null) {
                // remove source and layer
                this.mapView.removeLayer(mLayerName);
                this.mapView.removeSource(mSourceName);
            } else {
                // update source
                (mSource as mapboxgl.GeoJSONSource).setData(
                    buildPolyline(coordinates, { name: mSourceName }),
                );
            }
        } else if (!!coordinates) {
            // Create new source and layer
            this.mapView.addSource(mSourceName, {
                type: 'geojson',
                data: buildPolyline(coordinates, { name: mSourceName }),
            });
            this.mapView.addLayer({
                id: mLayerName,
                type: 'line',
                source: mSourceName,
                paint: { 'line-width': 3, ...(paint ?? {}) },
            });
            if (moveCamera !== false && coordinates.length > 0) {
                // Move camera to fit 'LngLatBounds'
                const bounds = new mapboxgl.LngLatBounds();
                for (const coord of coordinates) {
                    bounds.extend([coord[0], coord[1]]);
                }
                this.mapView.fitBounds(bounds, { padding: 40 });
            }
        }
    }

    /**
     * Update user location
     */
    updateMyLocation(current: IBlueDotLocation) {
        if (!this.mapLoaded || !current.lat || !current.lng) {
            return;
        }
        if (this.mapView.getLayer(LayerName.accuracy)) {
            this._setLayerVisible(LayerName.accuracy, true);
        }

        if (current.accuracy !== undefined) {
            this._setAccuracyCircle(current);
        }

        this.updateMyDirection(current);
    }

    /**
     * Update user direction
     */
    updateMyDirection(current: IBlueDotLocation) {
        if (!current.lat || !current.lng || current.direction == undefined) return;
        const markerCoord: mapboxgl.LngLatLike = [current.lng, current.lat];
        const direction = current.direction - this.mapView.getBearing();

        if (this.blueDotMarker === undefined || this.blueDotDirectionMarker === undefined) {
            this.blueDotMarker = this._addPin(markerCoord, 'blue_dot', []).setRotation(direction);
            this.blueDotDirectionMarker = this._addPin(
                markerCoord,
                'blue_dot_direction',
                [],
            ).setRotation(direction);
        } else {
            this.blueDotMarker.setLngLat(markerCoord).setRotation(direction);
            this.blueDotDirectionMarker.setLngLat(markerCoord).setRotation(direction);
        }
    }
    private _setAccuracyCircle(current: IBlueDotLocation) {
        if (!current.lat || !current.lng || current.accuracy == undefined) return;
        const circleOption = { steps: 64, units: 'meters' as turf.Units };
        const circle = turf.circle([current.lng, current.lat], current.accuracy, circleOption);

        const circlePolygon: Feature = {
            type: 'Feature',
            geometry: circle.geometry,
            properties: {},
        };

        if (this.mapView.getSource(SourceName.accuracy)) {
            this._updateSourceData(SourceName.accuracy, circlePolygon);
            this.mapView.moveLayer(LayerName.accuracy);
        } else {
            const circleGeoJson = getGeoJson([circlePolygon]);
            this.mapView.addSource(SourceName.accuracy, circleGeoJson);
            this.mapView.addLayer(getBlueDotAccuracyLayer());
        }
    }

    private _addPin(
        lngLat: [number, number],
        className: string,
        elements: HTMLElement[],
    ): mapboxgl.Marker {
        const pinDiv = document.createElement('div');
        pinDiv.className = className;

        elements.forEach((element) => {
            pinDiv.append(element);
        });

        return new mapboxgl.Marker(pinDiv).setLngLat(lngLat).addTo(this.mapView);
    }
    private _setLayerVisible(layout: string, visible: boolean) {
        this.mapView.setLayoutProperty(layout, 'visibility', visible ? 'visible' : 'none');
    }
    private _updateSourceData(source: string, data: Feature | FeatureCollection) {
        (this.mapView.getSource(source) as mapboxgl.GeoJSONSource).setData(data);
    }

    getStaticImageUrl(
        lat: number,
        lng: number,
        options?: Partial<{
            zoom: number;
            width: number;
            height: number;
        }>,
    ): string {
        const mZoom = options?.zoom ?? 16;
        const mWidth = options?.width ?? 500;
        const mHeight = options?.height ?? 280;
        return `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/${lng},${lat},${mZoom},0,0/${mWidth}x${mHeight}?access_token=${mapboxgl.accessToken}`;
    }

    showCenterMarker(
        visible: boolean,
        center?: { lat: number; lng: number },
        centerListener?: (lngLat: mapboxgl.LngLat) => void,
        editable?: boolean,
    ) {
        if (visible && center) {
            this._centerMarker.setLngLat([center.lng, center.lat]).addTo(this.mapView);
            this.mapView.flyTo({ center, zoom: 18, animate: false });
            if (editable !== false) {
                this.mapView.on('move', (ev) => {
                    if (this._centerMarker) {
                        const mCenter = ev.target.getCenter();
                        this._centerMarker.setLngLat(mCenter);
                        if (centerListener) centerListener(mCenter);
                    }
                });
            }
        }
    }
    getCenter() {
        if (this.mapView && this.mapLoaded) {
            return this.mapView.getCenter();
        } else {
            return null;
        }
    }

    flyTo(center: { lat: number; lng: number }, animate?: boolean) {
        this.mapView.easeTo({ center, animate });
    }
}
