import {
    PageContainer,
    ProCard,
    ProDescriptions,
    ProList,
    StatisticCard,
} from '@ant-design/pro-components';
import React, { useEffect, useMemo, useState } from 'react';
import {
    Space,
    Button,
    message,
    Typography,
    Tag,
    Flex,
    Divider,
    Descriptions,
    Badge,
    Alert,
    Modal,
    Steps,
    Input,
} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    CopyOutlined,
    CloseOutlined,
    CheckOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import {
    AddProjVenueModule,
    CloneProjVenueModule,
    DeleteProjVenueModule,
} from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { ResourceRole } from 'apis/UserApi';
import BaseMapEngine from 'services/BaseMapEngine';
import { useVenueState } from 'providers/VenueProvider';
import { updateVenue } from 'apis/VenueApi';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import CachedImage from 'components/CachedImage';
const { Text } = Typography;
const mapEngine = new BaseMapEngine();

const VenueOverviewScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const { fetchVenue, isLoading, venue } = useVenueState();
    const { activeProject, project, refreshProject, isMobile, isTablet } = useAppState();
    const navigate = useNavigate();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const isAdmin = hasAccess(ResourceRole.ADMIN, activeProject?.projId);
    const [modal, contextHolder] = Modal.useModal();
    const [keyword, setKeyword] = useState<string | undefined>(undefined);

    useEffect(() => {
        setKeyword(undefined);
    }, [venue]);

    function _handleEnabledVenue(enable: boolean, hasError: boolean) {
        if (!venue) return;
        modal
            .confirm({
                title: `Are you sure to ${enable ? 'enable' : 'disable'} ${venue.name}${hasError && enable ? ' anyway' : ''}?`,
            })
            .then(
                (confirmed) => {
                    if (confirmed) {
                        updateVenue(venue.id, {
                            projectId: venue.projectId,
                            name: venue.name,
                            center: venue.center,
                            enabled: enable,
                            id: venue.id,
                        }).then((resp) => {
                            fetchVenue(venue.id);
                            if (resp.response?.status === 200) {
                                message.success('Venue ' + enable ? 'enabled' : 'disabled');
                            }
                        });
                    }
                },
                () => {},
            );
    }

    const filteredMaps = useMemo(() => {
        if (!keyword) return venue?.maps ?? [];
        const mKeyword = keyword.toLowerCase();

        return (venue?.maps ?? []).filter((el) => {
            const fullText =
                el.name.toLowerCase().includes(mKeyword) ||
                el.id.toLowerCase().includes(mKeyword) ||
                `${el.level}`.includes(mKeyword);
            if (fullText) return true;

            let subFilter = false;
            let matched;
            if ((matched = mKeyword.match('survey=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(el.surveyRecords > 0 === flag)) return false;
                else subFilter = true;
            }
            if ((matched = mKeyword.match('beacon=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el.beaconPlanId === flag)) return false;
                else subFilter = true;
            }
            if ((matched = mKeyword.match('mask=(true|false)'))) {
                const flag = matched[1] === 'true';
                if (!(!!el.boundary === flag)) return false;
                else subFilter = true;
            }
            return subFilter;
        });
    }, [venue?.maps, keyword]);

    function _renderVenueMaps() {
        return (
            <ProList
                search={false}
                loading={isLoading}
                rowKey="id"
                itemLayout={isTablet ? 'vertical' : undefined}
                metas={{
                    title: { dataIndex: 'name' },
                    subTitle: {
                        dataIndex: 'level',
                        render: (dom, row) => <Text type="secondary">Level: {dom}</Text>,
                    },
                    description: {
                        dataIndex: 'id',
                        render: (dom, row) => (
                            <Text type="secondary" copyable={{ text: row.id }}>
                                ID: {dom}
                            </Text>
                        ),
                    },
                    actions: {
                        dataIndex: 'updatedAt',
                        render: (dom, row) => {
                            return [
                                <Space key={'k2-' + row.id}>
                                    {row.beaconPlanId ? (
                                        <Tag color="cyan" icon={<CheckOutlined />}>
                                            Beacon
                                        </Tag>
                                    ) : undefined}
                                </Space>,
                                <Space direction="vertical" align="end" key={'k1-' + row.id}>
                                    {!row.mapImg && row.boundary ? (
                                        <Tag color="purple">Polygon</Tag>
                                    ) : undefined}
                                </Space>,
                                <Space direction="vertical" align="end" key={'k0-' + row.id}>
                                    {row.surveyRecords == 0 || !row.mapAlign || !row.mapImg ? (
                                        <Space>
                                            {row.surveyRecords > 0 ? undefined : (
                                                <Tag color="red" icon={<CloseOutlined />}>
                                                    Survey
                                                </Tag>
                                            )}
                                            {!row.mapAlign || !row.mapImg ? (
                                                <Tag color="red" icon={<CloseOutlined />}>
                                                    Floor Plan
                                                </Tag>
                                            ) : undefined}
                                        </Space>
                                    ) : undefined}
                                    {row.boundary ? undefined : (
                                        <Tag color="red" icon={<CloseOutlined />}>
                                            Floor Plan Mask
                                        </Tag>
                                    )}
                                </Space>,
                            ];
                        },
                    },
                }}
                dataSource={filteredMaps}
                rowHoverable
                rowClassName="cursor-pointer"
                onItem={(record, index) => {
                    return {
                        onClick: (event) => {
                            if (record.venueId) {
                                navigate(`/project/venue/${record.venueId}/map`, {
                                    state: { mapId: record.id },
                                });
                            }
                        },
                    };
                }}
            />
        );
    }

    function _renderInfoArea() {
        if (!venue) return <></>;
        return (
            <>
                <ProCard ghost>
                    <ProDescriptions column={1} layout="vertical" dataSource={venue}>
                        <ProDescriptions.Item label="Name" dataIndex="name" />
                        <ProDescriptions.Item label="ID" dataIndex="id" copyable />
                        {venue?.extId ? (
                            <ProDescriptions.Item label="Ext. ID" dataIndex="extId" copyable />
                        ) : undefined}
                        <ProDescriptions.Item label="Center">
                            {venue.center.lat + ',' + venue.center.lng}
                        </ProDescriptions.Item>
                        <ProDescriptions.Item>
                            <CachedImage
                                style={{ width: '100%', height: 180, objectFit: 'cover' }}
                                alt="Map"
                                src={mapEngine.getStaticImageUrl(
                                    venue.center.lat,
                                    venue.center.lng,
                                )}
                            />
                        </ProDescriptions.Item>
                    </ProDescriptions>
                </ProCard>
                <Divider />
                <ProCard ghost>
                    <Flex justify="space-between">
                        {isEditor && project ? (
                            <Space>
                                <AddProjVenueModule
                                    key="btn-edit-venue"
                                    projectId={project.id}
                                    isEdit={true}
                                    editingVenue={venue}
                                    trigger={<Button icon={<EditOutlined />}>Edit</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        fetchVenue(venue.id);
                                        message.success('Venue updated');
                                    }}
                                />
                                <CloneProjVenueModule
                                    key="btn-clone-venue"
                                    processingProjectId={project.id}
                                    cloningVenue={venue}
                                    trigger={<Button icon={<CopyOutlined />}>Copy</Button>}
                                    onSuccess={() => {
                                        refreshProject(activeProject?.projId);
                                        fetchVenue(venue.id);
                                        message.success('Venue copied');
                                    }}
                                />
                            </Space>
                        ) : undefined}
                        {isAdmin ? (
                            <DeleteProjVenueModule
                                key="btn-delete-venue"
                                processingVenue={venue}
                                onSuccess={() => {
                                    refreshProject(activeProject?.projId);
                                    message.success('Venue deleted');
                                    navigate('/project/venue', { replace: true });
                                }}
                                trigger={
                                    <Button danger icon={<DeleteOutlined />}>
                                        Delete
                                    </Button>
                                }
                            />
                        ) : undefined}
                    </Flex>
                </ProCard>
            </>
        );
    }

    function _renderEnableAlert(pendingItem?: string[]) {
        if (!venue || !pendingItem || (venue?.enabled && pendingItem.length === 0))
            return undefined;
        return (
            <>
                {venue.enabled ? undefined : (
                    <Alert
                        message="Venue is not enabled."
                        description="This venue is only available for the client SDK if it is enabled."
                        type="warning"
                        style={{ marginBottom: 12 }}
                        showIcon
                        action={
                            isEditor ? (
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() =>
                                        _handleEnabledVenue(!venue?.enabled, pendingItem.length > 0)
                                    }
                                >
                                    {venue?.enabled ? 'Disable' : 'Enable'}
                                </Button>
                            ) : undefined
                        }
                    />
                )}
                {pendingItem.length > 0 ? (
                    <Alert
                        message="Venue is not setup properly."
                        description={
                            <div className="small-ul-wrapper">
                                Some issues were detected. Please ensure the venue is properly set
                                up:
                                <ul>
                                    {pendingItem.map((el, idx) => (
                                        <li key={`err-${idx}`}>{el}</li>
                                    ))}
                                </ul>
                            </div>
                        }
                        type="error"
                        style={{ marginBottom: 12 }}
                        showIcon
                        action={
                            isEditor && venue.enabled ? (
                                <Button
                                    type="text"
                                    size="small"
                                    onClick={() => _handleEnabledVenue(false, true)}
                                >
                                    Disable
                                </Button>
                            ) : undefined
                        }
                    />
                ) : undefined}
            </>
        );
    }

    function _renderHelp() {
        return <ViewDocumentModule trigger={<QuestionCircleOutlined />} docName="integration.md" />;
    }

    function _getStatue() {
        if (!venue) return { notCompleted: undefined, status: 0 };
        const mPending: string[] = [];
        const missingPackage = !venue?.publish;
        const missingMapAlign = venue?.maps.some((el) => !el.mapAlign || !el.mapImg);
        const missingMask = venue?.maps.some((el) => !el.boundary?.length);
        const missingSurvey = venue?.maps.some((el) => el.surveyRecords === 0);

        if (missingPackage) mPending.push('No site package is available.');
        if (missingMapAlign) mPending.push('Some map images are not ready.');
        if (missingMask) mPending.push('Some boundary masks are missing.');
        if (missingSurvey) mPending.push('The survey is incomplete.');

        let mStatus = 0;
        if (mPending.length === 0) {
            mStatus = 2;
        } else if (!missingMapAlign) {
            mStatus = 1;
        }
        return { notCompleted: mPending, status: mStatus };
    }

    const { notCompleted, status } = _getStatue();
    const mMapCount = (venue?.maps ?? []).length;
    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {contextHolder}
            <ProCard
                ghost
                gutter={8}
                bodyStyle={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
            >
                <ProCard ghost split="horizontal">
                    {_renderEnableAlert(notCompleted)}
                    <ProCard
                        bordered
                        title={`Maps (${!!keyword && keyword.length > 0 ? filteredMaps.length +'/' : ''}${mMapCount})`}
                        headStyle={{ paddingBottom: 0 }}
                        extra={
                            mMapCount > 0
                                ? [
                                      <Input.Search
                                          key="map-search-box"
                                          size="small"
                                          allowClear
                                          onSearch={(val) => setKeyword(val)}
                                          placeholder="Search a map"
                                      />,
                                  ]
                                : undefined
                        }
                    >
                        {_renderVenueMaps()}
                    </ProCard>
                </ProCard>
                <ProCard colSpan={isMobile ? undefined : 10} ghost direction="column">
                    <ProCard ghost>
                        <Flex justify="space-around" gap="small">
                            <StatisticCard
                                statistic={{
                                    title: 'Site engagement',
                                    value: venue?.publish?.count ?? '-',
                                    tip: 'Site package download count',
                                    description: venue?.publish ? (
                                        <Descriptions>
                                            <Descriptions.Item label="Ver." style={{ padding: 0 }}>
                                                {venue?.publish?.version ?? '-'}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    ) : (
                                        <Badge status="error" text="No site package" />
                                    ),
                                }}
                                loading={isLoading}
                            />
                            <StatisticCard
                                statistic={{
                                    title: (
                                        <Space>
                                            <span>Status</span>
                                            {_renderHelp()}
                                        </Space>
                                    ),
                                    value: ['Setup Venue', 'Ready to survey', 'Build your app'][
                                        status
                                    ],
                                    description: isTablet ? undefined : (
                                        <Steps size="small" current={status} items={[{}, {}, {}]} />
                                    ),
                                }}
                                loading={isLoading}
                            />
                        </Flex>
                    </ProCard>
                    <ProCard bordered style={{ margin: '8px 0' }}>
                        {_renderInfoArea()}
                    </ProCard>
                </ProCard>
            </ProCard>
        </PageContainer>
    );
};

export default VenueOverviewScreen;
