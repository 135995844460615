import { ProCard } from '@ant-design/pro-components';
import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Spin,
    Empty,
    Typography,
    Flex,
    Input,
    Space,
    Tooltip,
    Checkbox,
    message,
} from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    ImportOutlined,
} from '@ant-design/icons';
import { useAppState } from 'providers/AppStateProvider';
import { useVenueState } from 'providers/VenueProvider';
import FloorPlanMapView from 'components/maps/FloorPlanMapView';
import {
    FloorPlanMaskItem,
    FloorPlanMaskDto,
    updateFloorplanMask,
    generateMask,
} from 'apis/VenueApi';
import PolygonDrawingLayer, {
    IMaskItem,
    PolygonDrawingLayerRef,
} from 'components/maps/PolygonDrawingLayer';
import * as turf from '@turf/turf';
import { PolygonInputModule } from 'components/dialogs/PolygonInputDialog';
import LayerToolbar from 'components/maps/LayerToolbar';
import { CONTROL_POSITION_CLASSES } from 'services/BaseMapEngine';
import { ViewDocumentModule } from 'components/dialogs/DocumentDialog';
import FloorSelector from 'components/FloorSelector';
import CustomIcon from 'components/CustomIcon';
import LoadingOverlay from 'components/LoadingOverlay';
import { useAuth } from 'providers/AuthProvider';
import { ResourceRole } from 'apis/UserApi';

const { Text } = Typography;

export interface MaskItemConfig extends IMaskItem {
    area: number;
}

interface IGroupConfig {
    name: string;
    key: string;
    color: string;
    tips: string;
}
export const GroupConfig: Record<string, IGroupConfig> = {
    boundary: {
        name: 'Boundary',
        key: 'BOUNDARY',
        color: 'rgba(31, 119, 180, 0.3)',
        tips: 'The indoor area or a floor outline',
    },
    accessible: {
        name: 'Accessible',
        key: 'ACCESSIBLE',
        color: 'rgba(44, 160, 44, 0.3)',
        tips: 'The walkable area. (e.g floor area, doorway, stairs, etc.)',
    },
    inaccessible: {
        name: 'Inaccessible',
        key: 'INACCESSIBLE',
        color: 'rgba(250, 173, 20, 0.3)',
        tips: 'The area unable to be reached or entered. (e.g walls, void, open-to-below area, private areas, etc.)',
    },
    switchingZone: {
        name: 'Switching zone',
        key: 'SWITCHINGZONE',
        color: 'rgba(148, 103, 189, 0.3)',
        tips: 'The zone entry. (e.g lifts, stairs, exits, doorway, etc.)',
    },
};

const VenueFloorplanMaskScreen: React.FC = () => {
    const { hasAccess } = useAuth();
    const { venue, workingMap, floorPlanMask, isLoading, fetchFloorPlanMask } = useVenueState();
    const { project, isMobile } = useAppState();
    const [messageApi, contextHolder] = message.useMessage();
    const isEditor = hasAccess(ResourceRole.EDITOR, project?.id);

    const drawRef = useRef<PolygonDrawingLayerRef>();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [maskConfArr, setMaskConfArr] = useState<MaskItemConfig[]>([]);
    const [initMaskConf, setInitMaskConf] = useState<MaskItemConfig[]>([]);
    const hasChange = useRef<boolean>(false);
    const panelRef = useRef();

    const [hoverId, setHoverId] = useState<string | number | undefined>();
    const [errorVisible, setErrorVisible] = useState<boolean>(false);
    const [layerConfig, setLayerConfig] = useState<any>({
        translucent: true,
        grayscale: false,
    });

    useEffect(() => {
        _fetchData();
        setErrorVisible(false);
    }, [venue, workingMap]);

    useEffect(() => {
        if (floorPlanMask) _resetFloorMask(floorPlanMask);
    }, [floorPlanMask]);

    function _fetchData() {
        if (venue && workingMap) {
            fetchFloorPlanMask();
            hasChange.current = false;
        }
    }

    /**
     * Helper methods
     */
    function _getArea(lngLatPts: number[][]) {
        /* points: [lng, lat] */
        const polygon = turf.polygon([lngLatPts]);
        return turf.area(polygon);
    }

    async function _saveFloorPlanMask() {
        const data: FloorPlanMaskDto = {
            mapId: workingMap?.id!,
            boundary: undefined,
            accessible: [],
            inaccessible: [],
            switchingZone: [],
        };

        const polygonData = drawRef.current!.getAllLayers();
        const toMaskDto = (conf: MaskItemConfig): FloorPlanMaskItem => {
            const poly = polygonData.find((x) => x.id === conf.id);
            return {
                id: conf.id,
                name: conf.name,
                polygon: poly?.polygon.map((x) => [x[1], x[0]]) as any,
                enabled: conf.enabled,
            };
        };
        for (const el of maskConfArr ?? []) {
            if (el.groupId === GroupConfig.boundary.key) {
                if (data.boundary !== undefined) {
                    setErrorVisible(true);
                    return;
                }
                data.boundary = toMaskDto(el);
            } else if (el.groupId === GroupConfig.accessible.key) {
                data.accessible.push(toMaskDto(el));
            } else if (el.groupId === GroupConfig.inaccessible.key) {
                data.inaccessible.push(toMaskDto(el));
            } else if (el.groupId === GroupConfig.switchingZone.key) {
                data.switchingZone.push(toMaskDto(el));
            }
        }
        if (!data.boundary) {
            setErrorVisible(true);
            return;
        } else {
            setErrorVisible(false);
        }

        setIsSaving(true);
        try {
            if (workingMap?.venueId != undefined) {
                const resp = await updateFloorplanMask(workingMap?.venueId, data);
                messageApi.open({ type: 'success', content: 'Floor plan mask updated' });
            }
            setIsSaving(false);
        } catch (ex: any) {
            setIsSaving(false);
        } finally {
            _fetchData();
        }
    }

    function _resetFloorMask(maskData?: FloorPlanMaskDto, disableOld?: boolean) {
        if (!maskData) return;

        const toMaskItemConfig = (el: FloorPlanMaskItem, group: IGroupConfig): MaskItemConfig => ({
            id: el.id,
            name: el.name,
            enabled: el.enabled,
            visible: el.enabled,
            area: _getArea(el.polygon),
            groupId: group.key,
            polygon: el.polygon.map((x) => [x[1], x[0]]), // [lat, lng]
        });

        const boundaryConfig =
            maskData.boundary != null
                ? [toMaskItemConfig(maskData.boundary, GroupConfig.boundary)]
                : [];
        const accessibleConfig: MaskItemConfig[] = maskData.accessible.map((item) =>
            toMaskItemConfig(item, GroupConfig.accessible),
        );
        const inaccessibleConfig: MaskItemConfig[] = maskData.inaccessible.map((item) =>
            toMaskItemConfig(item, GroupConfig.inaccessible),
        );
        const switchingZoneConfig: MaskItemConfig[] = maskData.switchingZone.map((item) =>
            toMaskItemConfig(item, GroupConfig.switchingZone),
        );
        const oldData = disableOld
            ? (maskConfArr ?? [])?.map((el) => ({
                  ...el,
                  enabled: false,
                  visible: false,
              }))
            : [];

        const temp = [
            ...oldData,
            ...boundaryConfig,
            ...accessibleConfig,
            ...inaccessibleConfig,
            ...switchingZoneConfig,
        ];
        setMaskConfArr(temp);
        setInitMaskConf(temp);
        setTimeout(() => {
            if (drawRef.current?.resetAll) drawRef.current.resetAll();
        }, 200);
    }

    /**
     * Event listener
     */
    function onToggleVisible(id: string | number, flag: boolean) {
        if (drawRef.current) drawRef.current.setVisible(id, flag);
        setMaskConfArr((x) => {
            const cloned = [...x];
            const mIdx = cloned.findIndex((el) => el.id === id);
            cloned[mIdx].visible = flag;
            return cloned;
        });
    }
    function onToggleEnable(id: string | number, flag: boolean) {
        setMaskConfArr((x) => {
            const cloned = [...x];
            const mIdx = cloned.findIndex((el) => el.id === id);
            cloned[mIdx].enabled = flag;
            return cloned;
        });
        hasChange.current = true;
    }
    function onMaskRename(id: string | number, name: string) {
        setMaskConfArr((x) => {
            const cloned = [...x];
            const mIdx = cloned.findIndex((el) => el.id === id);
            cloned[mIdx].name = name;
            return cloned;
        });
        if (drawRef.current) drawRef.current.updateName(id, name);
        hasChange.current = true;
    }
    function handleSuggestMask() {
        if (!workingMap) return;
        setIsSaving(true);
        generateMask(workingMap?.id)
            .then((resp) => {
                _resetFloorMask(resp.data, true);
                hasChange.current = true;
            })
            .finally(() => {
                setIsSaving(false);
            });
    }
    /**
     * Components
     */
    function _renderMaskRows(conf: IGroupConfig, noEnable: boolean) {
        const groupItems = maskConfArr?.filter((el) => el.groupId == conf.key);
        if (!groupItems || groupItems.length == 0) {
            return (
                <Flex justify="center" align="center" style={{ padding: 8 }}>
                    <Typography.Text type="secondary">Empty</Typography.Text>
                </Flex>
            );
        }
        return groupItems.map((el, index) => {
            return (
                <Tooltip
                    key={el.id}
                    title={`${el.area.toFixed(1)} ㎡`}
                    placement="right"
                    mouseEnterDelay={0.5}
                >
                    <div
                        key={el.id}
                        data-mask-id={el.id}
                        style={{
                            padding: 8,
                            borderTop: index != 0 ? '1px solid rgba(5, 5, 5, 0.06)' : undefined,
                            backgroundColor:
                                hoverId == el.id && el.visible
                                    ? 'rgba(5, 5, 5, 0.1)'
                                    : 'transparent',
                        }}
                        onMouseEnter={() => {
                            if (el.visible && drawRef.current) drawRef.current.setHoverItem(el.id);
                        }}
                        onMouseLeave={() => {
                            if (el.visible && drawRef.current)
                                drawRef.current.setHoverItem(undefined);
                        }}
                    >
                        <Flex gap={8} align="center">
                            {!noEnable && isEditor && (
                                <Checkbox
                                    checked={el.enabled}
                                    onChange={(e) => {
                                        onToggleEnable(el.id, !el.enabled);
                                    }}
                                    title="Enable / Disable"
                                />
                            )}
                            <Button
                                size="small"
                                type="text"
                                icon={el.visible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                title="Show / Hide"
                                onClick={() => {
                                    onToggleVisible(el.id, !el.visible);
                                }}
                            />
                            <Input
                                style={{ paddingLeft: 10, paddingRight: 10, flex: 1 }}
                                placeholder="Enter polygon name"
                                defaultValue={el.name != '' ? el.name : undefined}
                                onChange={(e) => onMaskRename(el.id, e.target.value)}
                                size="small"
                                readOnly={!isEditor}
                            />
                            {isEditor ? (
                                <>
                                    <Button
                                        size="small"
                                        type="text"
                                        style={{ float: 'right' }}
                                        icon={<DeleteOutlined />}
                                        title="Remove"
                                        onClick={() => {
                                            if (drawRef.current) drawRef.current.removeById(el.id);
                                        }}
                                    />
                                    <PolygonInputModule
                                        trigger={
                                            <Button
                                                type="text"
                                                size="small"
                                                icon={<ImportOutlined />}
                                                title="Import polygon"
                                            />
                                        }
                                        getPosition={() =>
                                            drawRef.current
                                                ? drawRef.current!.findLayerById(el.id)?.polygon
                                                : undefined
                                        }
                                        positions={el.polygon}
                                        targetId={el.id}
                                        onSuccess={(result) => {
                                            if (drawRef.current && result?.id) {
                                                drawRef.current.removeById(result.id);
                                                drawRef.current.importPolygon(
                                                    new Date().getTime(),
                                                    conf.key,
                                                    result.coordinates,
                                                );
                                            }
                                        }}
                                    />
                                </>
                            ) : undefined}
                        </Flex>
                    </div>
                </Tooltip>
            );
        });
    }

    function _renderMaskGroupExtra(showAddMask: boolean, groupId: string) {
        const groupItems = (maskConfArr ?? [])?.filter((el) => el.groupId == groupId);
        const hasVisible = groupItems.findIndex((el) => el.visible) >= 0;
        return (
            <Space>
                {groupItems.length > 0 && (
                    <Button
                        type="text"
                        icon={hasVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        title="Show / Hide"
                        size="small"
                        onClick={() => {
                            const itemIds = groupItems.map((el) => el.id);
                            const flag = !hasVisible;
                            if (drawRef.current) drawRef.current.setVisible(itemIds, flag);
                            setMaskConfArr((x) => {
                                const cloned = [...x];
                                cloned.forEach((el) => {
                                    if (itemIds.includes(el.id)) el.visible = flag;
                                });
                                return cloned;
                            });
                        }}
                    />
                )}
                {showAddMask && isEditor ? (
                    <Button
                        type="text"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                            if (drawRef.current) drawRef.current.addPolygon(Date.now(), groupId);
                        }}
                        title="Add polygon"
                    />
                ) : undefined}
            </Space>
        );
    }

    function _renderMaskGroup(isMultiple: boolean = true, config: IGroupConfig) {
        return (
            <ProCard.Group
                title={config.name}
                size="small"
                extra={_renderMaskGroupExtra(
                    isMultiple ||
                        (!isMultiple &&
                            (maskConfArr ?? []).filter((el) => el.groupId == config.key).length <=
                                0),
                    config.key,
                )}
                headStyle={{ backgroundColor: config.color, borderRadius: '4px 4px 0 0' }}
                headerBordered
                bordered
                collapsible
                defaultCollapsed={isMultiple}
                direction="column"
                tooltip={config.tips}
                style={{ borderColor: config.color }}
            >
                {_renderMaskRows(config, !isMultiple)}
            </ProCard.Group>
        );
    }

    function _renderPanelContent() {
        if (isLoading) return <Spin />;

        return (
            <Flex vertical style={{ padding: 12 }} gap={12}>
                <Text type="secondary">
                    Map generation and positioning experience can be adjusted with a floor plan
                    mask. Click
                    <ViewDocumentModule
                        docName="floorplanMask.md"
                        btnType="link"
                        trigger={<span>here</span>}
                    />
                    to learn more.
                </Text>
                {hasChange.current ? (
                    <Text type="warning">Note: You have unsaved change.</Text>
                ) : undefined}
                {_renderMaskGroup(false, GroupConfig.boundary)}
                {errorVisible ? (
                    <Typography.Text type="danger">Require exactly one Boundary.</Typography.Text>
                ) : undefined}
                {_renderMaskGroup(true, GroupConfig.accessible)}
                {_renderMaskGroup(true, GroupConfig.inaccessible)}
                {_renderMaskGroup(true, GroupConfig.switchingZone)}
                {_renderPanelActions()}
            </Flex>
        );
    }

    function _renderPanelActions() {
        if (!isEditor) return undefined;
        return (
            <>
                <Flex gap="1em" style={{ paddingTop: 20 }} justify="flex-end">
                    <Button
                        onClick={() => {
                            hasChange.current = false;
                            _resetFloorMask(floorPlanMask);
                        }}
                    >
                        Reset
                    </Button>
                    <Button type="primary" onClick={_saveFloorPlanMask}>
                        Save
                    </Button>
                </Flex>
                {workingMap && workingMap?.surveyRecords > 0 ? (
                    <Button style={{ marginTop: 12 }} onClick={handleSuggestMask}>
                        <Flex gap={8} align="center" justify="center">
                            <CustomIcon
                                icon="stars"
                                size={18}
                                color='yellow'
                            />
                            <span>Generate mask from survey</span>
                        </Flex>
                    </Button>
                ) : undefined}
            </>
        );
    }

    function _renderPanel() {
        return (
            <ProCard
                colSpan={isMobile ? undefined : '30%'}
                className="venue-panel"
                ghost
                split="horizontal"
                ref={panelRef}
            >
                <FloorSelector warnIds={venue?.maps.filter((x) => !x.boundary).map((y) => y.id)} />
                {workingMap?.id && _renderPanelContent()}
            </ProCard>
        );
    }
    function _renderMap() {
        return (
            <div
                style={{
                    height: '100%',
                    width: '100%',
                    backgroundColor: '#ddd',
                    position: 'relative',
                }}
            >
                {venue && workingMap ? (
                    <FloorPlanMapView
                        imageUrl={workingMap.mapImg}
                        mapAlign={workingMap.mapAlign}
                        initCenter={workingMap?.mapAlign?.center ?? venue.center}
                        opacity={layerConfig?.translucent ? 0.5 : 1}
                        grayscale={layerConfig?.grayscale ?? false}
                    >
                        <LayerToolbar
                            position={CONTROL_POSITION_CLASSES.topleft}
                            options={[
                                { name: 'Translucent', key: 'translucent', defaultChecked: true },
                                { name: 'Grayscale', key: 'grayscale', defaultChecked: false },
                            ]}
                            onChange={(key, checked) => {
                                setLayerConfig((x: any) => ({ ...x, [key]: checked }));
                            }}
                        />
                        <PolygonDrawingLayer
                            ref={drawRef as any}
                            initData={initMaskConf}
                            editable={isEditor}
                            onHover={setHoverId}
                            onCreated={(item) => {
                                hasChange.current = true;
                                setMaskConfArr((x) => {
                                    return [
                                        ...x,
                                        {
                                            id: item.id,
                                            name: item.name,
                                            enabled: true,
                                            visible: true,
                                            area: _getArea(item.polygon),
                                            groupId: item.groupId,
                                            polygon: item.polygon,
                                        },
                                    ];
                                });
                            }}
                            onRemoved={(item) => {
                                hasChange.current = true;
                                setMaskConfArr((state) => {
                                    const mIndex = maskConfArr.findIndex((el) => el.id === item.id);
                                    const newState = [...state];
                                    newState.splice(mIndex, 1);
                                    return newState;
                                });
                            }}
                            onChanged={() => {
                                hasChange.current = true;
                            }}
                            onClick={(id) => {
                                const element = document.querySelector(`[data-mask-id='${id}']`);
                                if (element) {
                                    element.scrollIntoView({ behavior: 'smooth' });
                                }
                            }}
                        />
                    </FloorPlanMapView>
                ) : (
                    <Empty
                        image={false}
                        description={
                            !workingMap ? 'Select floor plan to continue.' : 'No map data :('
                        }
                    />
                )}
            </div>
        );
    }

    if (!project || !venue) return <Spin />;
    return (
        <ProCard
            style={{ height: '100%' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            <LoadingOverlay visible={isSaving} />
            {contextHolder}
            {_renderPanel()}
            {_renderMap()}
        </ProCard>
    );
};

export default VenueFloorplanMaskScreen;
