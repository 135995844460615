import { PageContainer, ProCard, ProList } from '@ant-design/pro-components';
import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    message,
    Typography,
    Empty,
    Tooltip,
    Flex,
    List,
    Alert,
    Popconfirm,
    Descriptions,
    Badge,
    notification,
    Dropdown,
    Space,
} from 'antd';
import {
    CloudDownloadOutlined,
    CloudUploadOutlined,
    CaretRightFilled,
    LoadingOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
    MinusCircleFilled,
    DownOutlined,
    UpOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import {
    AddVenueFingerprintModule,
    PublishVenueFingerprintModule,
} from 'components/dialogs/HandleProjVenueDialog';
import { useAuth } from 'providers/AuthProvider';
import { useAppState } from 'providers/AppStateProvider';
import { ResourceRole } from 'apis/UserApi';
import { useVenueState } from 'providers/VenueProvider';
import moment from 'moment';
import { formatDateAgo } from 'utils/DateUtils';
import {
    cancelBatchJob,
    createBatchJob,
    getBatchInfo,
    JobBatchInfoDto,
    JobBatchParmaDto,
    JobBatchStatus,
} from 'apis/VenueApi';
import BuildOptionDrawer, { BuildOptionDrawerRef } from 'components/dialogs/BuildOptionDrawer';
const { Text } = Typography;

const BatchJobRow: React.FC<{
    item: JobBatchInfoDto;
    advance?: boolean;
    allowRefresh?: boolean;
}> = (props) => {
    const { fetchBatchJobs, fetchPackages } = useVenueState();
    const [info, setInfo] = useState<JobBatchInfoDto>(props.item);
    const [completed, setCompleted] = useState<{ result: boolean; data?: JobBatchInfoDto }>({
        result: false,
    });

    const _syncJobInfo = () => {
        getBatchInfo(props.item.id).then((resp) => {
            if (resp.data) {
                // update status
                setInfo(resp.data);
                if (
                    [JobBatchStatus.CANCELLED, JobBatchStatus.DONE, JobBatchStatus.FAIL].includes(
                        resp.data.status,
                    )
                ) {
                    // task completed
                    setCompleted({ result: true, data: resp.data });
                }
            }
        });
    };
    useEffect(() => {
        setInfo(props.item);
    }, [props.item]);

    useEffect(() => {
        const isInitRunning = ![
            JobBatchStatus.CANCELLED,
            JobBatchStatus.DONE,
            JobBatchStatus.FAIL,
        ].includes(props.item.status);
        if (props.allowRefresh && isInitRunning) {
            const interval = setInterval(() => _syncJobInfo(), 30 * 1000);
            if (completed.result === true) {
                clearInterval(interval);
                notification.open({
                    type:
                        completed.data?.status === JobBatchStatus.DONE
                            ? 'success'
                            : completed.data?.status === JobBatchStatus.FAIL
                              ? 'error'
                              : 'info',
                    message: `Site package state: ${completed.data?.status ?? 'unknown'}`,
                    description: 'See result on site package settings.',
                    duration: null,
                });
                fetchBatchJobs(0, undefined);
                fetchPackages(0);
            }
            return () => clearInterval(interval);
        }
    }, [completed]);

    function _buildCancelBtn() {
        return (
            <Popconfirm
                key={`ext-cancel-${info.id}`}
                title="Cancel build"
                description="Are you sure to cancel this batch?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                    cancelBatchJob(info.id).then((resp) => {
                        message.info('Task canceled.');
                        fetchBatchJobs();
                    });
                }}
            >
                <Button
                    size="small"
                    title="Cancel batch"
                    style={{ backgroundColor: 'transparent' }}
                >
                    <Typography.Text type="secondary">Cancel</Typography.Text>
                </Button>
            </Popconfirm>
        );
    }

    function _getItemState(item: JobBatchInfoDto) {
        const state = {
            type: 'info',
            icon: <LoadingOutlined style={{ fontSize: 18 }} />,
            cancelable: true,
            status: 'processing',
        };
        if (item.status === JobBatchStatus.DONE) {
            state.type = 'success';
            state.status = 'success';
            state.icon = <CheckCircleFilled />;
            state.cancelable = false;
        } else if (item.status === JobBatchStatus.FAIL) {
            state.type = 'error';
            state.status = 'error';
            state.icon = <CloseCircleFilled />;
            state.cancelable = false;
        } else if (item.status === JobBatchStatus.CANCELLED) {
            state.type = 'error';
            state.status = 'error';
            state.icon = <MinusCircleFilled />;
            state.cancelable = false;
        }
        return state;
    }

    const _renderBatchContent = (state: any, item: JobBatchInfoDto) => {
        return (
            <Descriptions style={{ width: '60vw' }}>
                <Descriptions.Item label="Build" style={{ padding: 0, width: '120px' }}>
                    {item.id.substring(item.id.length - 5)}
                </Descriptions.Item>
                <Descriptions.Item label="Start at" style={{ padding: 0 }}>
                    <Tooltip title={moment(item.createdAt).format('DD-MMMM-YYYY HH:mm')}>
                        {formatDateAgo(item.createdAt)}
                    </Tooltip>
                </Descriptions.Item>
                <Descriptions.Item label="Status" style={{ padding: 0 }}>
                    <Badge status={state.status as any} text={item.status} />
                </Descriptions.Item>
                {item.error && props.advance ? (
                    <Descriptions.Item label="Error" style={{ padding: 0 }}>
                        <Typography>
                            <pre style={{ margin: 0 }}>{item.error}</pre>
                        </Typography>
                    </Descriptions.Item>
                ) : undefined}
            </Descriptions>
        );
    };

    const state = _getItemState(info);
    return (
        <>
            {props.advance ? (
                <Flex justify="space-between" style={{ flex: 1, flexWrap: 'wrap' }}>
                    {_renderBatchContent(state, info)}
                    {state.cancelable ? _buildCancelBtn() : undefined}
                </Flex>
            ) : (
                <Alert
                    style={{ flex: 1 }}
                    className="alert-msg-no-padding"
                    message={_renderBatchContent(state, info)}
                    type={state.type as any}
                    icon={state.icon}
                    showIcon
                    action={state.cancelable ? _buildCancelBtn() : undefined}
                    closable={completed.result}
                />
            )}
        </>
    );
};

const BatchSection: React.FC = () => {
    const { isSuperAdmin } = useAuth();
    const { isLoading, venue, fetchBatchJobs, batchJobs, batchJobPage, batchRunning } =
        useVenueState();
    const [showSummary, setShowSummary] = useState<boolean>(true);
    const hasSuperAccess = isSuperAdmin();
    const [messageApi, contextHolder] = message.useMessage();
    const [buildMode, setBuildMode] = useState<string>('SURVEY:OPT');
    const buildOptionRef = useRef<BuildOptionDrawerRef>();
    const mBuildOptions = [
        {
            key: 'SURVEY:OPT',
            title: 'New Build',
            desc: 'Generate optimized fingerprint and config.',
        },
        {
            key: 'SURVEY:CUSTOM',
            title: 'Custom Build',
            desc: 'Build with custom build option.',
            superAdminOnly: true,
        },
        {
            key: 'SURVEY:CONF',
            title: 'Update Config',
            desc: 'Published fingerprint is used and only the config is updated.',
        },
    ];

    useEffect(() => {
        if (!!venue) {
            fetchBatchJobs(0, isSuperAdmin() ? undefined : true);
        }
    }, [venue]);

    function _onBuildClick(params?: JobBatchParmaDto) {
        if (!venue?.id) return;
        createBatchJob({
            venueId: venue.id,
            type: 'SURVEY',
            params: params ?? {
                updateFp: buildMode !== 'SURVEY:CONF',
                genBeacon: buildMode === 'SURVEY:OPT' ? true : undefined,
                minimal: buildMode === 'SURVEY:OPT' ? true : undefined,
            },
        }).then((resp) => {
            messageApi.info('Task submitted.');
            fetchBatchJobs(0);
        });
    }

    function _renderBuildButton() {
        return (
            <Dropdown.Button
                key="ext-build-btn"
                type="primary"
                icon={<DownOutlined />}
                disabled={hasBatchRunning}
                menu={{
                    items: mBuildOptions
                        .filter((x) => {
                            if (x.superAdminOnly && !isSuperAdmin()) return false;
                            return true;
                        })
                        .map((el) => {
                            return {
                                label: (
                                    <Flex vertical style={{ maxWidth: 300 }}>
                                        <strong>{el.title}</strong>
                                        <Text type="secondary">{el.desc}</Text>
                                    </Flex>
                                ),
                                key: el.key,
                            };
                        }),
                    selectable: true,
                    defaultSelectedKeys: [mBuildOptions[0].key],
                    onClick: (conf) => {
                        setBuildMode(conf.key)
                        if (conf.key === 'SURVEY:CUSTOM' && buildOptionRef.current) {
                            buildOptionRef.current.open();
                        }
                    },
                }}
                onClick={() => {
                    if (buildMode === 'SURVEY:CUSTOM' && buildOptionRef.current) {
                        buildOptionRef.current.open();
                    } else {
                        _onBuildClick();
                    }
                }}
            >
                <CaretRightFilled />
                {mBuildOptions.find((el) => el.key === buildMode)?.title ?? 'New Build'}
            </Dropdown.Button>
        );
    }

    function _renderBatchList(
        moreVisible: boolean,
        data?: JobBatchInfoDto[],
        summaryMode?: boolean,
    ) {
        return (
            <>
                <ProList<JobBatchInfoDto>
                    search={false}
                    rowKey="id"
                    size="small"
                    bordered={false}
                    dataSource={data ?? []}
                    ghost
                    loading={isLoading}
                    pagination={
                        !summaryMode && moreVisible
                            ? {
                                  defaultCurrent: 1,
                                  defaultPageSize: 10,
                                  total: batchJobPage?.total,
                                  onChange: (page, size) => {
                                      fetchBatchJobs(page - 1);
                                  },
                                  size: 'small'
                              }
                            : undefined
                    }
                    renderItem={(item) => {
                        return (
                            <List.Item style={{ padding: 4 }}>
                                <BatchJobRow
                                    item={item}
                                    advance={moreVisible && !summaryMode}
                                    allowRefresh={!moreVisible}
                                />
                            </List.Item>
                        );
                    }}
                />
                {moreVisible && hasSuperAccess ? (
                    <Button
                        type="text"
                        size="small"
                        className="w100"
                        onClick={() => setShowSummary((val) => !val)}
                        icon={showSummary ? <DownOutlined /> : <UpOutlined />}
                    >
                        Show {showSummary ? 'more' : 'less'}
                    </Button>
                ) : undefined}
            </>
        );
    }

    const hasBatchRunning = batchRunning && batchRunning?.length > 0;
    return (
        <>
            {hasBatchRunning ? (
                <Alert
                    type="info"
                    showIcon
                    message="Generating site package"
                    description={_renderBatchList(false, batchRunning, true)}
                />
            ) : undefined}
            {hasSuperAccess || !hasBatchRunning ? (
                <ProCard title="Batches" extra={[_renderBuildButton()]}>
                    {contextHolder}
                    <Typography.Paragraph type="secondary">
                        {
                            'If the map content, floor plan mask, beacon plan, or survey data is updated, you will need to regenerate the site package in order to apply the changes. This process typically takes approximately 4 minutes to complete. You can view the status of this process on this page.'
                        }
                    </Typography.Paragraph>
                    {hasSuperAccess
                        ? _renderBatchList(true, batchJobs, hasBatchRunning ? false : showSummary)
                        : undefined}
                    {hasSuperAccess ? (
                        <BuildOptionDrawer
                            onClosed={(result) => {
                                if (!!result) _onBuildClick(result);
                            }}
                            ref={buildOptionRef as any}
                        />
                    ) : undefined}
                </ProCard>
            ) : undefined}
        </>
    );
};

const VenuePackageScreen: React.FC = () => {
    const { hasAccess, isSuperAdmin } = useAuth();
    const { isLoading, venue, venuePackages, fetchPackages } = useVenueState();
    const { activeProject, project } = useAppState();
    const isEditor = hasAccess(ResourceRole.EDITOR, activeProject?.projId);
    const [messageApi, contextHolder] = message.useMessage();
    const hasSuperAccess = isSuperAdmin();

    useEffect(() => {
        if (!!venue) {
            fetchPackages(0);
        }
    }, [venue]);

    function _renderCreateBtn() {
        if (!venue) return undefined;
        return (
            <AddVenueFingerprintModule
                key="btn-add-sitePackage"
                venueId={venue.id}
                trigger={
                    <Button type="dashed" icon={<CloudUploadOutlined />}>
                        Upload site package
                    </Button>
                }
                onSuccess={() => {
                    fetchPackages(0);
                    messageApi.success('Site package uploaded');
                }}
            />
        );
    }

    function _renderListContent() {
        if (!venue) return <Empty />;
        return (
            <ProList
                search={false}
                rowKey="id"
                headerTitle="Site packages"
                size="small"
                loading={isLoading}
                pagination={{
                    size: 'small',
                    defaultCurrent: 1,
                    defaultPageSize: 5,
                    total: venuePackages?._metadata?.pagination?.total,
                    // showTotal: (total) => `Total ${total} items`,
                    onChange: (page, size) => {
                        fetchPackages(page - 1);
                    },
                }}
                toolBarRender={hasSuperAccess && project ? () => [_renderCreateBtn()] : undefined}
                showActions="hover"
                metas={{
                    title: {
                        dataIndex: 'basic',
                        search: false,
                        render: (_, row) => (
                            <Tooltip title={`Version: ${row.version}`}>
                                <Text>{row.desc}</Text>
                            </Tooltip>
                        ),
                    },
                    subTitle: {
                        dataIndex: 'createdAt',
                        search: false,
                        render: (_, row) => (
                            <Text
                                type="secondary"
                                className="text-08"
                                style={{ fontWeight: 400 }}
                                title={new Date(row.createdAt).toString()}
                            >
                                <Space>
                                    <ClockCircleOutlined />
                                    {formatDateAgo(row.createdAt)}
                                </Space>
                            </Text>
                        ),
                    },
                    description: {
                        dataIndex: 'note',
                        render: (_, row) => {
                            const strNote = row.note?.msg
                                ? row.note.msg
                                : row.publishAt
                                  ? '<No release note>'
                                  : undefined;
                            return (
                                <Typography.Paragraph
                                    type="secondary"
                                    ellipsis={{
                                        rows: 2,
                                        expandable: 'collapsible',
                                        symbol: (flag) => (flag ? 'Less' : 'More'),
                                    }}
                                >
                                    {strNote}
                                </Typography.Paragraph>
                            );
                        },
                    },
                    actions: {
                        render: (text, row, index) => [
                            <Flex key={`pkg-act-${index}`} vertical align="end">
                                <Space>
                                    <div key={`package-pub-btn-${index}`} style={{ width: '80px' }}>
                                        {isEditor ? (
                                            row.isLive ? (
                                                <Button
                                                    style={{
                                                        background: '#87d068',
                                                        fontWeight: 'bold',
                                                        border: 'none',
                                                        color: 'white',
                                                        width: '100%',
                                                    }}
                                                    disabled
                                                >
                                                    {'Live'}
                                                </Button>
                                            ) : (
                                                <PublishVenueFingerprintModule
                                                    key="btn-add-venueFingerprints"
                                                    fingerprintId={row.id}
                                                    trigger={
                                                        <Button style={{ width: '100%' }}>
                                                            Publish
                                                        </Button>
                                                    }
                                                    onSuccess={() => {
                                                        fetchPackages(0);
                                                        messageApi.success(
                                                            'Site package published',
                                                        );
                                                    }}
                                                />
                                            )
                                        ) : undefined}
                                    </div>
                                    {hasSuperAccess ? (
                                        <Button
                                            key={`package-dl-btn-${index}`}
                                            icon={<CloudDownloadOutlined />}
                                            title="Download"
                                            onClick={() => {
                                                window.open(row.path, '_blank');
                                            }}
                                        />
                                    ) : undefined}
                                </Space>
                                <Text type="secondary" className="text-08">
                                    {row.publishAt
                                        ? `Published at: ${moment(row.publishAt).format('YYYY-MM-DD HH:mm')}`
                                        : undefined}
                                </Text>
                            </Flex>,
                        ],
                        search: false,
                    },
                }}
                dataSource={venuePackages?.data ?? []}
            />
        );
    }

    return (
        <PageContainer breadcrumb={undefined} title={null} header={{ title: undefined }}>
            {contextHolder}
            <Flex vertical gap={16}>
                <BatchSection />
                {_renderListContent()}
            </Flex>
        </PageContainer>
    );
};

export default VenuePackageScreen;
