import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Drawer, Form, Select, Space, Typography } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
    EditableFormInstance,
    EditableProTable,
    ProColumns,
    ProForm,
    ProFormSelect,
    ProFormSwitch,
    ProFormText,
} from '@ant-design/pro-components';
import { useAuth } from 'providers/AuthProvider';
import { IUserInfo, ResourceEntry, ResourceRole, updateUserInfo } from 'apis/UserApi';
import { useAppState } from 'providers/AppStateProvider';

export const accessRoleOptions = [
    {
        label: 'Admin',
        value: ResourceRole.ADMIN,
        desc: 'Can view and edit projects; Manage venues, licenses and users.',
    },
    {
        label: 'Editor',
        value: ResourceRole.EDITOR,
        desc: 'Can view projects; Manage venues; View licenses and users.',
    },
    {
        label: 'Viewer',
        value: ResourceRole.VIEWER,
        desc: 'Can view projects, venues and search trace by trace id.',
    },
];

export type UserAccessDrawerRef = {
    open: (data: IUserInfo) => void;
};

export type UserAccessProps = {
    onClosed?: (updated?: boolean) => void;
};

const UserAccessDrawer = React.forwardRef<UserAccessDrawerRef, UserAccessProps>((props, ref) => {
    const [userAccessForm] = Form.useForm();
    const { user, isSuperAdmin } = useAuth();
    const { isTablet } = useAppState();
    const [userDetail, setUserDetail] = useState<IUserInfo>();
    const projectNameRef = useRef<Map<string, string>>();
    const tableFormRef = useRef<EditableFormInstance<ResourceEntry>>();

    useEffect(() => {
        if (!!user?.resources) {
            const projNameMap = new Map();
            for (let i = 0; i < user.resources.length; i++) {
                projNameMap.set(user.resources[i].projId, user.resources[i].name);
            }
            projectNameRef.current = projNameMap;
        }
    }, [user]);

    useImperativeHandle(ref, () => ({
        open(data: IUserInfo) {
            if (userAccessForm) {
                userAccessForm.setFieldsValue({
                    name: data.name,
                    email: data.email,
                    role: data.role,
                    enabled: data.enabled,
                });
            }
            setUserDetail(data);
            if (tableFormRef.current) tableFormRef.current.resetFields();
            return;
        },
    }));

    const onClose = (flag?: boolean) => {
        if (props.onClosed) props.onClosed(flag);
        setUserDetail(undefined);
    };

    if (!isSuperAdmin()) return <></>;

    const _appendProject = (projId: string) => {
        setUserDetail((x) => {
            if (!x) return x;
            const resources = [...(x?.resources ?? [])];
            resources.push({ projId, role: ResourceRole.VIEWER });
            return { ...x, resources };
        });
    };

    const _removeProject = (projId: string) => {
        setUserDetail((x) => {
            if (!x) return x;
            const resources = (x?.resources ?? []).filter((el) => el.projId !== projId);
            return { ...x, resources };
        });
    };

    const _updateUserInfo = async (data: IUserInfo) => {
        try {
            const resp = await updateUserInfo(data.id, data);
            return resp.response?.status === 200;
        } catch (ex: any) {
            return false;
        }
    };

    const columns: ProColumns<ResourceEntry>[] = [
        {
            title: 'Name',
            dataIndex: 'name',
            readonly: true,
            renderText: (text, record) => {
                return projectNameRef.current?.get(record.projId);
            },
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            valueType: 'select',
            valueEnum: {
                [ResourceRole.ADMIN]: { text: 'Admin' },
                [ResourceRole.EDITOR]: { text: 'Editor' },
                [ResourceRole.VIEWER]: { text: 'Viewer' },
            },
            tooltip: {
                title: (
                    <ul>
                        {accessRoleOptions.map((el) => (
                            <li key={el.value}>
                                <b>{el.label}</b>: {el.desc}
                            </li>
                        ))}
                    </ul>
                ),
            },
        },
        {
            title: 'Action',
            valueType: 'option',
            width: 100,
            render: () => null,
        },
    ];

    return (
        <Drawer
            title="User Access"
            onClose={() => onClose()}
            open={!!userDetail}
            width={isTablet ? '90%' : '60%'}
        >
            <Typography.Title level={4} style={{ marginTop: 0 }}>
                User info
            </Typography.Title>
            <ProForm
                className="tiny-row"
                layout="horizontal"
                form={userAccessForm}
                readonly={false}
                onFinish={async (values) => {
                    const tableValues = tableFormRef.current?.getFieldsValue() as any;
                    const mResources = (userDetail?.resources ?? []).map((el) => {
                        if (el.projId in tableValues) {
                            const mRoleValue =
                                (tableValues[el.projId] as any)?.role ?? ResourceRole.VIEWER;
                            return { projId: el.projId, role: mRoleValue };
                        }
                        return el;
                    });
                    const result = await _updateUserInfo({
                        ...userDetail,
                        name: values.name,
                        role: values.role,
                        enabled: values.enabled,
                        resources: mResources,
                    } as IUserInfo);
                    onClose(result);
                    return result;
                }}
                submitter={{
                    searchConfig: { submitText: 'Update' },
                    resetButtonProps: false,
                }}
            >
                <ProFormText
                    name="email"
                    label="Email address"
                    placeholder="Enter email address"
                    readonly
                />
                <ProFormText
                    name="name"
                    label="Display name"
                    placeholder="Enter user name"
                    rules={[{ required: true, message: 'Please enter user name!' }]}
                />
                <ProFormSelect
                    name="role"
                    label="Role"
                    valueEnum={{
                        USER: 'User',
                        SUPER_ADMIN: 'Super admin',
                    }}
                    placeholder="Please select a role"
                    rules={[{ required: true, message: 'Please select a role!' }]}
                />
                <ProFormSwitch
                    name="enabled"
                    label="Enabled"
                    placeholder="Enter user name"
                    required
                />
                <Typography.Title level={4}>
                    {`Project (${userDetail?.resources.length ?? 0})`}
                </Typography.Title>
                <Select
                    showSearch
                    placeholder={
                        <Space>
                            <SearchOutlined />
                            <span>Search a project to add.</span>
                        </Space>
                    }
                    onChange={(value, option) => _appendProject(value!)}
                    value={null}
                    style={{ width: '100%', marginBottom: 8 }}
                    options={(user?.resources ?? [])
                        .filter(
                            (el) =>
                                !(userDetail?.resources ?? []).some(
                                    (val) => val.projId === el.projId,
                                ),
                        )
                        .map((item) => ({
                            value: item.projId,
                            label: item.name ?? item.projId,
                        }))}
                    filterOption={(input, option) => {
                        return (option?.label ?? '')
                            .toLowerCase()
                            .includes((input ?? '').toLowerCase());
                    }}
                    filterSort={(a, b) =>
                        (a?.label ?? '').toLowerCase().localeCompare((b?.label ?? '').toLowerCase())
                    }
                />
                <EditableProTable
                    editableFormRef={tableFormRef}
                    rowKey="projId"
                    size="small"
                    ghost
                    recordCreatorProps={false}
                    columns={columns}
                    value={userDetail?.resources ?? []}
                    style={{ marginBottom: 24 }}
                    editable={{
                        type: 'single',
                        editableKeys: (userDetail?.resources ?? []).map((x) => x.projId),
                        actionRender: (row, config, defaultDoms) => {
                            return [
                                <a key="pin" onClick={() => _removeProject(row.projId)}>
                                    Remove
                                </a>,
                            ];
                        },
                    }}
                    showSorterTooltip={true}
                />
            </ProForm>
        </Drawer>
    );
});

export default UserAccessDrawer;
