import { ProCard } from '@ant-design/pro-components';
import {
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    Flex,
    Form,
    InputNumber,
    Row,
    Select,
    Slider,
    Space,
    Typography,
    message,
} from 'antd';
import { ExpandOutlined, FullscreenOutlined, PushpinOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import AlignmentMapView, { IHelpConfig } from 'components/maps/AlignmentMapView';
import CornerInputModule from 'components/dialogs/CornerInputDialog';
import { useAppState } from 'providers/AppStateProvider';
import { useNavigate } from 'react-router-dom';
import { useVenueState } from 'providers/VenueProvider';
import { LatLngDto, toCornersDto, updateVenueMapAlign, VenueMapMetaDto } from 'apis/VenueApi';
import PositionOffsetPanel from 'components/maps/PositionOffsetPanel';
import CustomIcon from 'components/CustomIcon';
import MeasurementTool, { MeasurementToolRef } from 'components/maps/MeasurementTool';
import ImageOverlayRotated from 'components/maps/ImageOverlayRotated';
import AlignChangedModule, { AlignChangedModuleRef } from 'components/dialogs/AlignChangedDialog';
import AnchorInputModule from 'components/dialogs/AnchorInputDialog';
import AdjustSlider from 'components/AdjustSlider';
import { AlignmentUtil, AlignResult } from 'utils/AlignmentUtil';
const { Text } = Typography;

const w100 = { width: '100%' };
const mb0 = { marginBottom: 0 };

const AlignmentScreen: React.FC = () => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const { isMobile } = useAppState();
    const { venue, workingMap, fetchVenue } = useVenueState();

    // Action reference
    const initMapData = useRef<VenueMapMetaDto>();
    const changeDialogRef = useRef<AlignChangedModuleRef>();
    const isReadyRef = useRef<boolean>(false);

    // Align param
    const alignUtilRef = useRef<AlignmentUtil>(new AlignmentUtil());
    const [alignResult, setAlignResult] = useState<AlignResult>({
        ...workingMap?.mapAlign,
        center: workingMap?.mapAlign?.center ?? venue?.center,
        angle: workingMap?.mapAlign?.angle ?? 0,
        scale: workingMap?.mapAlign?.scale ?? 100,
    } as any);

    // Align reference
    const [refMapId, setRefMapId] = useState<string | undefined>();
    const [alignMode, setAlignMode] = useState<string>('basic');
    const [alignOptions, setAlignOptions] = useState<IHelpConfig>({
        opacity: 1,
        grayscale: false,
        stroke: true,
        showBound: false,
    });
    const measurementRef = useRef<MeasurementToolRef>();

    useEffect(() => {
        if (!!workingMap?.mapImg) {
            initMapData.current = workingMap;
            if (!alignUtilRef.current) alignUtilRef.current = new AlignmentUtil();
            alignUtilRef.current
                .init({ imageUrl: workingMap.mapImg })
                .then((r) => {
                    isReadyRef.current = true;
                    if (!workingMap.mapAlign && venue?.center) {
                        _handleInputChange({ center: venue!.center });
                    }
                })
                .catch(console.error);
        }

        () => {
            initMapData.current = undefined;
            alignUtilRef.current.init(undefined);
            isReadyRef.current = false;
        };
    }, [workingMap]);

    const _handleInputChange = (param: { center?: LatLngDto; scale?: number; angle?: number }) => {
        if (!!alignUtilRef.current) {
            const mCenter = param?.center != undefined ? param.center : alignResult.center;
            const mScale = param?.scale != undefined ? param.scale : alignResult.scale;
            const mAngle = param?.angle != undefined ? param.angle : alignResult.angle;
            const result = alignUtilRef.current.fromCenterScaleAngle(mCenter, mScale, mAngle);
            if (!!result) {
                setAlignResult(result);
            }
        }
    };

    const _handleCenterOffset = (direction: any, meter: number) => {
        const lastCenter = alignResult?.center ?? workingMap?.mapAlign?.center ?? venue!.center;
        const offsetCenter = AlignmentUtil.offsetPoint(lastCenter, direction, meter);
        _handleInputChange({ center: offsetCenter });
    };

    const _handlePreciseInput = (polygon: any) => {
        if (!!alignUtilRef.current && polygon) {
            const result = alignUtilRef.current.fromCorners(polygon);
            if (!!result) setAlignResult(result);
        }
    };

    const _onClickSubmit = () => {
        if (!alignResult || !workingMap) {
            messageApi.info('Alignment no change.');
            navigate(-1);
        } else {
            const hasBeaconOrMask = !!workingMap.beaconPlanId || !!workingMap.boundary;
            updateVenueMapAlign(workingMap.venueId, {
                id: workingMap.id,
                venueId: workingMap.venueId,
                mapAlign: {
                    center: alignResult.center,
                    angle: alignResult.angle,
                    scale: alignResult.scale,
                    corners: toCornersDto(alignResult.corners),
                    size: { width: alignResult.size.width, height: alignResult.size.height },
                    sizeInMeters: alignResult.sizeInMeters,
                },
            }).then((res) => {
                fetchVenue(venue?.id);
                messageApi.success('Alignment saved.');
                if (hasBeaconOrMask && !!changeDialogRef.current && !!initMapData.current) {
                    changeDialogRef.current.open(initMapData.current);
                } else {
                    navigate(-1);
                }
            });
        }
    };

    const _onClickBack = () => {
        if (confirm('You have unsaved changes, are you sure you want to leave?') == true) {
            navigate(-1);
        }
    };

    const _renderOffsetTool = () => {
        if (!workingMap || !venue) return;
        return (
            <Collapse
                className="collapse-offset-tool"
                size="small"
                style={{ padding: 0, margin: '4px 0 16px 0', backgroundColor: 'white' }}
                expandIcon={() => <ExpandOutlined title="offset position" rotate={45} />}
                expandIconPosition="end"
                items={[
                    {
                        key: 'pos-offset-panel',
                        label: 'Offset position',
                        children: (
                            <PositionOffsetPanel keyboard={false} onOffset={_handleCenterOffset} />
                        ),
                    },
                ]}
            />
        );
    };

    const _renderBasicForm = () => {
        if (!workingMap || !venue) return undefined;
        return (
            <>
                <Space.Compact style={w100}>
                    <div className="input-group">
                        <label htmlFor="latitude" title="Center latitude">
                            Latitude
                        </label>
                        <InputNumber
                            style={w100}
                            disabled
                            name="latitude"
                            title="Center latitude"
                            defaultValue={workingMap?.mapAlign?.center.lat ?? venue?.center.lat}
                            value={alignResult?.center.lat}
                            size="small"
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="longitude" title="Center longitude">
                            Longitude
                        </label>
                        <InputNumber
                            style={w100}
                            disabled
                            name="longitude"
                            title="Center longitude"
                            defaultValue={workingMap?.mapAlign?.center.lng ?? venue?.center.lng}
                            value={alignResult?.center.lng}
                            size="small"
                        />
                    </div>
                </Space.Compact>
                {_renderOffsetTool()}
                <div className="input-group" style={{ marginBottom: 16 }}>
                    <label htmlFor="angle" title="angle">
                        Angle
                    </label>
                    <InputNumber
                        name="angle"
                        min={0}
                        max={360}
                        style={w100}
                        size="small"
                        suffix="°"
                        defaultValue={workingMap.mapAlign?.angle ?? 0}
                        value={alignResult.angle}
                        onChange={(e) =>
                            e !== null ? _handleInputChange({ angle: e }) : undefined
                        }
                    />
                    <AdjustSlider
                        min={0}
                        max={360}
                        step={0.0001}
                        value={alignResult.angle}
                        onChange={(e) =>
                            e !== null ? _handleInputChange({ angle: e }) : undefined
                        }
                    />
                </div>

                <div className="input-group">
                    <label htmlFor="scale" title="scale">
                        Scale
                    </label>
                    <InputNumber
                        name="scale"
                        min={0}
                        style={w100}
                        size="small"
                        suffix="%"
                        defaultValue={workingMap.mapAlign?.scale ?? 100}
                        value={alignResult.scale}
                        onChange={(e) =>
                            e !== null ? _handleInputChange({ scale: e }) : undefined
                        }
                    />
                    <AdjustSlider
                        min={0}
                        max={200}
                        step={0.0001}
                        value={alignResult.scale}
                        onChange={(e) =>
                            e !== null ? _handleInputChange({ scale: e }) : undefined
                        }
                    />
                </div>
            </>
        );
    };

    const _renderAdvanceForm = () => {
        if (!workingMap?.mapImg || !venue) return <span>Not available</span>;
        const mCorners = alignResult?.corners
            ? Array.isArray(alignResult.corners)
                ? toCornersDto(alignResult.corners)
                : alignResult.corners
            : workingMap.mapAlign?.corners;
        return (
            <div className="input-group">
                <Row gutter={8}>
                    <CornerInputModule
                        imageUrl={workingMap.mapImg}
                        // positions={alignResult?.corners ?? workingMap.mapAlign?.corners ?? []}
                        trigger={
                            <Col sm={{ flex: '100%' }} xxl={{ flex: '50%' }}>
                                <Button className="w100" size="small" icon={<FullscreenOutlined />}>
                                    Align with corners
                                </Button>
                            </Col>
                        }
                        onSuccess={({ cornerMode, corners, polygon }) => {
                            _handlePreciseInput(polygon);
                        }}
                    />
                    <AnchorInputModule
                        imageUrl={workingMap.mapImg}
                        trigger={
                            <Col sm={{ flex: '100%' }} xxl={{ flex: '50%' }}>
                                <Button className="w100" size="small" icon={<PushpinOutlined />}>
                                    Align with anchors
                                </Button>
                            </Col>
                        }
                        onSuccess={(result) => {
                            _handlePreciseInput(result);
                        }}
                    />
                </Row>
                <div style={{ marginTop: 12 }}>
                    <span>Corners:</span>
                    <pre className="small-remark" style={{ margin: 0 }}>
                        Top Left: {mCorners?.tl.lat},{mCorners?.tl.lng}
                        <br />
                        Top Right: {mCorners?.tr.lat},{mCorners?.tr.lng}
                        <br />
                        Bottom Right: {mCorners?.br.lat},{mCorners?.br.lng}
                        <br />
                        Bottom Left: {mCorners?.bl.lat},{mCorners?.bl.lng}
                        <br />
                    </pre>
                </div>
            </div>
        );
    };

    const _renderHelpTool = () => {
        if (!workingMap || !venue) return undefined;
        return (
            <>
                <Flex justify="center" align="center" gap={6}>
                    <span>Opacity (%): </span>
                    <Slider
                        style={{ flex: 1, marginTop: 0, marginBottom: 0 }}
                        min={0}
                        max={1}
                        step={0.01}
                        tooltip={{ formatter: (val) => Math.floor((val ?? 0) * 100) + '%' }}
                        defaultValue={1}
                        value={alignOptions.opacity}
                        onChange={(e) => setAlignOptions((x) => ({ ...x, opacity: e }))}
                    />
                </Flex>
                <Space style={{ margin: '0.25rem 0' }}>
                    <Form.Item style={mb0}>
                        <Checkbox
                            checked={alignOptions.grayscale}
                            onChange={(e) =>
                                setAlignOptions((x) => ({
                                    ...x,
                                    grayscale: e.target.checked,
                                }))
                            }
                        >
                            Grayscale
                        </Checkbox>
                    </Form.Item>
                    <Form.Item style={mb0}>
                        <Checkbox
                            checked={alignOptions.stroke}
                            onChange={(e) =>
                                setAlignOptions((x) => ({
                                    ...x,
                                    stroke: e.target.checked,
                                }))
                            }
                        >
                            Border
                        </Checkbox>
                    </Form.Item>
                    {workingMap.boundary ? (
                        <Form.Item style={mb0}>
                            <Checkbox
                                checked={alignOptions.showBound}
                                onChange={(e) =>
                                    setAlignOptions((x) => ({
                                        ...x,
                                        showBound: e.target.checked,
                                    }))
                                }
                            >
                                Boundary
                            </Checkbox>
                        </Form.Item>
                    ) : undefined}
                </Space>
                <Form.Item label="Reference map" name="refMapId">
                    <Select
                        size="small"
                        className="w100"
                        onChange={setRefMapId}
                        placeholder="Select a reference map"
                        allowClear
                        options={venue.maps.map((x) => ({
                            label: x.name,
                            value: x.id,
                            disable: !x.mapAlign,
                        }))}
                    />
                </Form.Item>
                <Button
                    size="small"
                    title="Measure distance"
                    className="w100"
                    onClick={() => {
                        if (!!measurementRef.current) measurementRef.current.startMeasurement();
                    }}
                >
                    <Flex align="center" justify="center" gap={16}>
                        <CustomIcon icon="ruler" size={18} />
                        <span>Measure distance</span>
                    </Flex>
                </Button>
            </>
        );
    };

    const _renderApproxForm = () => {
        if (!workingMap || !venue) return undefined;
        return (
            <Form>
                {contextHolder}
                <Card
                    className="full-weight-tab"
                    size="small"
                    tabList={[
                        {
                            label: 'Basic',
                            key: 'basic',
                        },
                        {
                            label: 'Advance',
                            key: 'advance',
                        },
                    ]}
                    activeTabKey={alignMode}
                    onTabChange={setAlignMode}
                >
                    {alignMode === 'basic' ? _renderBasicForm() : _renderAdvanceForm()}
                </Card>

                <Card size="small" style={{ margin: '12px 0px' }}>
                    {_renderHelpTool()}
                </Card>

                <Form.Item>
                    <Flex justify="end" gap="middle">
                        <Button htmlType="reset" onClick={_onClickBack}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" onClick={_onClickSubmit}>
                            Save
                        </Button>
                    </Flex>
                </Form.Item>
            </Form>
        );
    };

    const _renderMap = () => {
        if (!workingMap?.mapImg || !venue) {
            return (
                <div style={{ margin: 'auto' }}>
                    <Text type="secondary">No floor plan for preview.</Text>
                </div>
            );
        }
        const refMapInfo = venue.maps.find((x) => x.id === refMapId);
        return (
            <div style={{ height: '100%', width: '100%', backgroundColor: 'rgb(0, 0, 0, 0.05)' }}>
                <AlignmentMapView
                    editable={alignMode === 'basic'}
                    imageUrl={workingMap.mapImg}
                    geojsonData={workingMap.boundary}
                    initCenter={workingMap?.mapAlign?.center ?? venue.center}
                    alignResult={alignResult ?? workingMap?.mapAlign}
                    onCenterChange={(center) => _handleInputChange({ center })}
                    config={alignOptions}
                >
                    {refMapInfo?.mapImg && refMapInfo.mapAlign ? (
                        <ImageOverlayRotated
                            imgSrc={refMapInfo.mapImg}
                            opacity={0.7}
                            positions={[
                                refMapInfo.mapAlign.corners.tl,
                                refMapInfo.mapAlign.corners.tr,
                                refMapInfo.mapAlign.corners.br,
                                refMapInfo.mapAlign.corners.bl,
                            ]}
                            fitBounds={false}
                        />
                    ) : (
                        <></>
                    )}
                    <MeasurementTool ref={measurementRef as any} />
                </AlignmentMapView>
            </div>
        );
    };

    return (
        <ProCard
            style={{ height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
            split={isMobile ? 'horizontal' : 'vertical'}
            className="full-content-height"
        >
            <ProCard
                title={<b>{workingMap?.name}</b>}
                colSpan="30%"
                className="venue-panel"
                headStyle={{ backgroundColor: '#e6f4ffaa', padding: '8px 24px' }}
                headerBordered
                bodyStyle={{ padding: 12, backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
            >
                {_renderApproxForm()}
            </ProCard>
            {_renderMap()}
            <AlignChangedModule ref={changeDialogRef as any} onClose={() => navigate(-1)} />
        </ProCard>
    );
};

export default AlignmentScreen;
