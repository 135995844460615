import { Button, Descriptions, Flex, Modal, Space, Table, Typography } from 'antd';
import { useState } from 'react';
import { CopyOutlined, InfoCircleOutlined, SwapOutlined } from '@ant-design/icons';
import CustomIcon from 'components/CustomIcon';
import { AlignmentDto, LatLngDto } from 'apis/VenueApi';
import { distance } from '@turf/turf';
import { useAppState } from 'providers/AppStateProvider';
import { AlignResult } from 'utils/AlignmentUtil';

interface AlignInfoDialogProps {
    name?: string;
    imgUrl?: string;
    alignResult?: AlignResult | AlignmentDto;
    precise?: boolean;
}

export const AlignInfoDialog: React.FC<AlignInfoDialogProps> = (props) => {
    const { isMobile } = useAppState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [meterInPx, setMeterInPx] = useState<boolean>(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const _getPixInMeter = () => {
        if (!props.alignResult?.size?.width || !props.alignResult?.sizeInMeters?.width) return 0.1;
        const mCorners = props.alignResult?.corners;
        if (mCorners) {
            const diagonal = Array.isArray(mCorners)
                ? [mCorners[0], mCorners[2]]
                : [
                      [mCorners.tl.lat, mCorners.tl.lng],
                      [mCorners.br.lat, mCorners.br.lng],
                  ];
            const pxDistance = Math.sqrt(
                props.alignResult.size.width ** 2 + props.alignResult.size.height ** 2,
            );
            const meterDistance = distance(
                [diagonal[0][1], diagonal[0][0]],
                [diagonal[1][1], diagonal[1][0]],
                { units: 'meters' },
            );
            return meterDistance / pxDistance;
        } else {
            return props.alignResult.sizeInMeters.width / props.alignResult.size.width;
        }
    };

    const handleCopyConfig = () => {
        navigator.clipboard.writeText(
            JSON.stringify({
                name: props.name,
                imageUrl: props.imgUrl,
                pixInMeter: 1 / _getPixInMeter(),
                ...props.alignResult,
            }),
        );
    };

    if (!props.alignResult?.size?.width) return <></>;
    const conf = props.alignResult!;
    const mCorners = Array.isArray(conf.corners)
        ? conf.corners
        : [
              [conf.corners.tl.lat, conf.corners.tl.lng],
              [conf.corners.tr.lat, conf.corners.tr.lng],
              [conf.corners.br.lat, conf.corners.br.lng],
              [conf.corners.bl.lat, conf.corners.bl.lng],
          ];

    const getPolygonGeoJson = (corners: number[][]) => {
        const obj = {
            type: 'Feature',
            properties: {},
            geometry: {
                coordinates: [
                    [
                        [corners[0][1], corners[0][0]],
                        [corners[3][1], corners[3][0]],
                        [corners[2][1], corners[2][0]],
                        [corners[1][1], corners[1][0]],
                        [corners[0][1], corners[0][0]],
                    ],
                ],
                type: 'Polygon',
            },
        };
        return JSON.stringify(obj, null, 2);
    };

    const getPointGeoJson = (point: LatLngDto) => {
        const obj = {
            type: 'Feature',
            properties: {},
            geometry: {
                coordinates: [point.lng, point.lat],
                type: 'Point',
            },
        };
        return JSON.stringify(obj, null, 2);
    };

    const dataSource = [
        [
            'Image',
            <a href={props.imgUrl} target="_blank">
                Download
            </a>,
        ],
        ['Image Size', `${conf.size?.width}×${conf.size?.height} px`],
        ['Area', `${conf.sizeInMeters.width.toFixed(2)}×${conf.sizeInMeters.height.toFixed(2)} m`],
        [
            <Space>
                <span>{meterInPx ? 'Meter in pixel' : 'Pixel in meter'}</span>
                <Button
                    type="text"
                    size="small"
                    icon={<SwapOutlined style={{ color: 'grey' }} />}
                    onClick={() => setMeterInPx((x) => !x)}
                />
            </Space>,
            meterInPx ? `1 px = ${_getPixInMeter()} meter` : `1 meter = ${1 / _getPixInMeter()} px`,
        ],
        [
            'Center',
            <Flex align="center">
                <span style={{ flex: 1 }}>
                    {conf.center.lat},{conf.center.lng}
                </span>
                <Typography.Text
                    copyable={{
                        tooltips: 'Copy GeoJSON',
                        text: () => getPointGeoJson(conf.center),
                    }}
                />
            </Flex>,
        ],
        ['Angle', `${conf.angle}°`],
        ['Scale', `${conf.scale}%`],
        [
            'Corners',
            <Flex align="center">
                <Descriptions column={1}>
                    <Descriptions.Item label="Top Left">
                        {mCorners[0][0]},{mCorners[0][1]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Top Right">
                        {mCorners[1][0]},{mCorners[1][1]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Bottom Right">
                        {mCorners[2][0]},{mCorners[2][1]}
                    </Descriptions.Item>
                    <Descriptions.Item label="Bottom Left">
                        {mCorners[3][0]},{mCorners[3][1]}
                    </Descriptions.Item>
                </Descriptions>
                <Typography.Text
                    copyable={{
                        tooltips: 'Copy GeoJSON',
                        text: () => getPolygonGeoJson(mCorners),
                    }}
                />
            </Flex>,
        ],
    ];
    return (
        <>
            <Button type="text" onClick={showModal} title="Alignment config" size="small">
                <CustomIcon icon="area" size={24} />
            </Button>
            <Modal
                title={`${props.name} - Alignment config${props.precise ? '' : ' (Temporary)'}`}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={false}
                width={isMobile ? '95%' : '70%'}
            >
                {props.precise ? undefined : (
                    <Typography.Text type="secondary" italic>
                        <InfoCircleOutlined /> Noted: The configuration below is based on the latest
                        calculated results via the Alignment tool.
                    </Typography.Text>
                )}
                <Table
                    columns={[
                        { title: 'k', width: 150, render: (val, record) => record[0] },
                        { title: 'v', render: (val, record) => record[1] },
                    ]}
                    rowKey={(record) => `conf-key-${record[0]}`}
                    dataSource={dataSource}
                    pagination={false}
                    showHeader={false}
                    size="small"
                />
                <Button
                    icon={<CopyOutlined />}
                    onClick={handleCopyConfig}
                    style={{ marginTop: '1rem' }}
                >
                    Copy to clipboard
                </Button>
            </Modal>
        </>
    );
};
export default AlignInfoDialog;
