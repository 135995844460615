import { ModalForm } from '@ant-design/pro-components';
import {
    Form,
    Typography,
    Flex,
    Space,
    Radio,
    Input,
    Button,
    Tooltip,
    message,
    Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { BaseDialogProps } from './BaseDialogProps.type';
import { QuestionCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { CornersDto } from 'apis/VenueApi';
import CustomIcon from 'components/CustomIcon';
import { PolygonUtil } from 'utils/PolygonUtil';
const { Paragraph, Text } = Typography;

const twoCornerOptions = [
    { key: 'tl,tr', name: 'Top Left + Top Right', icon: 'topCorner', rotate: 0 },
    { key: 'tl,br', name: 'Top Left + Bottom Right', icon: 'bevelCorner', rotate: 90 },
    { key: 'tl,bl', name: 'Top Left + Bottom Left', icon: 'topCorner', rotate: -90 },
    { key: 'tr,br', name: 'Top Right + Bottom Right', icon: 'topCorner', rotate: 90 },
    { key: 'tr,bl', name: 'Top Right + Bottom Left', icon: 'bevelCorner', rotate: 0 },
    { key: 'br,bl', name: 'Bottom Right + Bottom Left', icon: 'topCorner', rotate: 180 },
];

const LatLngPairItem: React.FC<any> = (props) => {
    const prefix = props.prefix;
    const prefixIndex = ['tl', 'tr', 'br', 'bl'];
    const mIndex = prefixIndex.indexOf(prefix) + 1;
    if (props.disabled) {
        return <div />;
    }
    const mFieldRules: any = [
        {
            type: 'number',
            required: props.required,
            transform: (val: any) => {
                if (isNaN(val) || isNaN(parseFloat(val))) return false;
                return Number(val);
            },
        },
    ];
    const mTabIndex = props?.tabIndex ?? 1;
    return (
        <Form.Item noStyle {...props}>
            <Space style={{ alignItems: 'normal' }}>
                {mIndex == 2 || mIndex == 3 ? (
                    <div className="circle-num">{mIndex}</div>
                ) : undefined}
                <Space.Compact>
                    <Form.Item name={[prefix, 'lat']} noStyle rules={mFieldRules}>
                        <Input
                            placeholder="Latitude"
                            style={{ width: '50%' }}
                            tabIndex={mTabIndex * 10}
                        />
                    </Form.Item>
                    <Form.Item name={[prefix, 'lng']} noStyle rules={mFieldRules}>
                        <Input
                            placeholder="Longitude"
                            style={{ width: '50%' }}
                            tabIndex={mTabIndex * 10 + 1}
                        />
                    </Form.Item>
                </Space.Compact>
                {mIndex == 1 || mIndex == 4 ? (
                    <div className="circle-num">{mIndex}</div>
                ) : undefined}
            </Space>
        </Form.Item>
    );
};

export type CornerInputProp = BaseDialogProps & {
    imageUrl: string;
    positions?: CornersDto | [number, number][];
};

const CornerInputModule: React.FC<CornerInputProp> = (props) => {
    const [cornerAlignForm] = Form.useForm();
    const [isOpen, serIsOpen] = useState<boolean>(false);
    const [cornerMode, setCornerMode] = useState('4');
    const [twoCornerMode, setTwoCornerMode] = useState('tr,bl');
    const [submittable, setSubmittable] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [imageSize, setImageSize] = useState<any>();

    // Watch all values
    const values = Form.useWatch([], cornerAlignForm);

    useEffect(() => {
        cornerAlignForm
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [values]);

    useEffect(() => {
        if (isOpen && props.positions) {
            const pos = props.positions;
            if (Array.isArray(pos) && pos.length >= 4) {
                cornerAlignForm.setFieldsValue({
                    tl: { lat: Number(pos[0][0]), lng: Number(pos[0][1]) },
                    tr: { lat: Number(pos[1][0]), lng: Number(pos[1][1]) },
                    br: { lat: Number(pos[2][0]), lng: Number(pos[2][1]) },
                    bl: { lat: Number(pos[3][0]), lng: Number(pos[3][1]) },
                });
            } else {
                handleObjInput(pos);
            }
        } else if (isOpen) {
            cornerAlignForm.resetFields();
        }
    }, [props.positions, isOpen]);

    function handlePairsInput(key: string, obj: any, parts: string[]) {
        const nonEmptyArr = parts.filter(Boolean).map(Number);
        if (nonEmptyArr.length === 2) {
            cornerAlignForm.setFieldsValue({
                [key]: { lat: nonEmptyArr[0], lng: nonEmptyArr[1] },
            });
        } else if (nonEmptyArr.length === 8) {
            cornerAlignForm.setFieldsValue({
                tl: { lat: nonEmptyArr[0], lng: nonEmptyArr[1] },
                tr: { lat: nonEmptyArr[2], lng: nonEmptyArr[3] },
                br: { lat: nonEmptyArr[4], lng: nonEmptyArr[5] },
                bl: { lat: nonEmptyArr[6], lng: nonEmptyArr[7] },
            });
        }
    }

    function handleObjInput(obj: any) {
        const isValidObj =
            typeof obj?.tl?.lat === 'number' &&
            typeof obj?.tl?.lng === 'number' &&
            typeof obj?.tr?.lat === 'number' &&
            typeof obj?.tr?.lng === 'number' &&
            typeof obj?.br?.lat === 'number' &&
            typeof obj?.br?.lng === 'number' &&
            typeof obj?.bl?.lat === 'number' &&
            typeof obj?.bl?.lng === 'number';
        if (isValidObj) {
            cornerAlignForm.setFieldsValue(obj);
        }
    }

    function toNumValues(obj: any) {
        const toNumObj = (entry: any) => ({
            lat: Number(entry?.lat),
            lng: Number(entry?.lng),
        });
        const result: any = {};
        if (obj.tl) result.tl = toNumObj(obj.tl);
        if (obj.tr) result.tr = toNumObj(obj.tr);
        if (obj.br) result.br = toNumObj(obj.br);
        if (obj.bl) result.bl = toNumObj(obj.bl);
        return result as CornersDto;
    }

    function handleCopyConfig() {
        navigator.clipboard.writeText(JSON.stringify(cornerAlignForm.getFieldsValue()));
        messageApi.open({ type: 'success', content: 'Copied' });
    }

    function _handleImgLoaded(event: any) {
        const img = event.target;
        const _imageSize = PolygonUtil.getRatio(img.naturalWidth, img.naturalHeight);
        setImageSize(_imageSize);
    }

    const _allow34 = '3' === cornerMode || '4' === cornerMode;
    const allowTL = _allow34 || ('2' == cornerMode && twoCornerMode.includes('tl'));
    const allowTR = _allow34 || ('2' == cornerMode && twoCornerMode.includes('tr'));
    const allowBR = '4' === cornerMode || ('2' == cornerMode && twoCornerMode.includes('br'));
    const allowBL = _allow34 || ('2' == cornerMode && twoCornerMode.includes('bl'));

    return (
        <ModalForm
            form={cornerAlignForm}
            title={'Align with corners coordinates'}
            trigger={props.trigger}
            width={'80%'}
            modalProps={{ style: { top: 24 } }}
            submitter={{
                render: (submitterProps) => {
                    return (
                        <Flex justify="space-between" className="w100">
                            <Tooltip
                                title="Tip: You can enter all values at once by pasting them to the first input field,
                    separated with a space, comma, line-break or designed JSON format. The numbers above indicate the fill order."
                            >
                                <Button
                                    type="text"
                                    shape="circle"
                                    icon={
                                        <QuestionCircleOutlined
                                            style={{ color: 'rgba(0, 0, 0, 0.45)' }}
                                        />
                                    }
                                />
                            </Tooltip>
                            <Space>
                                <Button
                                    type="primary"
                                    disabled={!submittable}
                                    onClick={submitterProps.submit}
                                >
                                    Preview
                                </Button>
                            </Space>
                        </Flex>
                    );
                },
            }}
            onFinish={async (strValues) => {
                const values = toNumValues(strValues);
                let polygon;
                if (cornerMode === '4') {
                    polygon = PolygonUtil.fromFourCorner(values);
                } else if (cornerMode === '3') {
                    polygon = PolygonUtil.fromThreeCorner(values);
                } else if (cornerMode === '2') {
                    polygon = PolygonUtil.fromTwoCorner(values, imageSize);
                }
                if (props.onSuccess) props.onSuccess({ cornerMode, corners: values, polygon });
                return true;
            }}
            onOpenChange={serIsOpen}
            onValuesChange={(changed, allValue) => {
                const mKey = Object.keys(changed);
                if (mKey.length === 1) {
                    const strValue = changed[mKey[0]]?.lat ?? '';
                    const strValuePart = strValue.split(/[\s|,]/);
                    if (strValuePart.length > 1 && strValue.includes('{')) {
                        try {
                            const mJsonObj = JSON.parse(strValue);
                            handleObjInput(mJsonObj);
                        } catch (e) {
                            /* do nothing */
                        }
                    } else if (strValuePart.length > 1) {
                        handlePairsInput(mKey[0], changed, strValuePart);
                    }
                }
            }}
        >
            {contextHolder}
            <p>
                Fill in distinct WGS84 coordinates for at least two corners of the floor plan. The
                application calculates the best possible fit without changing the aspect ratio of
                the floor plan image.
            </p>
            <Paragraph>
                <Text type="secondary"></Text>
            </Paragraph>

            <Space>
                <Text>Align with: </Text>
                <Radio.Group
                    defaultValue="4"
                    value={cornerMode}
                    onChange={(e) => setCornerMode(e.target.value)}
                >
                    <Radio.Button value="2">Two corners</Radio.Button>
                    <Radio.Button value="3">Three corners</Radio.Button>
                    <Radio.Button value="4">Four corners</Radio.Button>
                </Radio.Group>
            </Space>

            {cornerMode === '2' ? (
                <Paragraph style={{ marginTop: '1rem' }}>
                    <Space>
                        <Text>Corners: </Text>
                        <Select
                            style={{ width: 300 }}
                            onChange={(val) => setTwoCornerMode(val)}
                            value={twoCornerMode}
                            options={twoCornerOptions.map((el) => {
                                return {
                                    value: el.key,
                                    label: (
                                        <Flex justify="start" align="center" gap="middle">
                                            <CustomIcon
                                                icon={el.icon}
                                                style={{ transform: `rotate(${el.rotate}deg)` }}
                                                title={el.name}
                                            />
                                            <span>{el.name}</span>
                                        </Flex>
                                    ),
                                };
                            })}
                        />
                    </Space>
                </Paragraph>
            ) : undefined}

            <Flex justify="center" style={{ margin: '2rem 0px' }}>
                <Flex vertical justify="space-between" flex={1}>
                    <LatLngPairItem
                        prefix="tl"
                        disabled={!allowTL}
                        required={allowTL}
                        tabIndex={1}
                    />
                    <LatLngPairItem
                        prefix="bl"
                        disabled={!allowBL}
                        required={allowBL}
                        tabIndex={4}
                    />
                </Flex>
                <img
                    src={props.imageUrl!}
                    style={{ width: '30%', margin: '0 1rem', border: '5px solid #eeeeee', flex: 1 }}
                    onLoad={_handleImgLoaded}
                />
                <Flex vertical justify="space-between" flex={1}>
                    <LatLngPairItem
                        prefix="tr"
                        disabled={!allowTR}
                        required={allowTR}
                        tabIndex={2}
                    />
                    <LatLngPairItem
                        prefix="br"
                        disabled={!allowBR}
                        required={allowBR}
                        tabIndex={3}
                    />
                </Flex>
            </Flex>
        </ModalForm>
    );
};

export default React.memo(CornerInputModule, (prev, curr) => {
    return prev.imageUrl === curr.imageUrl;
});
