import React from 'react';
import { Button, Flex, Slider } from 'antd';
import { SliderSingleProps } from 'antd/lib';
import {
    DoubleRightOutlined,
    DoubleLeftOutlined,
    RightOutlined,
    LeftOutlined,
} from '@ant-design/icons';

const AdjustSlider: React.FC<
    SliderSingleProps & { value: number; onChange: (val: number) => void }
> = (props) => {
    function _handleOnChange(val: number) {
        if (props.onChange) props.onChange(val);
    }
    function _adjustSlider(offset: number) {
        _handleOnChange(props.value + offset);
    }
    const mStep = props?.step ?? 1;
    return (
        <Flex align="center" gap="small">
            <Button
                icon={<DoubleLeftOutlined />}
                size="small"
                title={`${-1 * mStep * 100}`}
                onClick={() => _adjustSlider(-1 * mStep * 100)}
            />
            <Button
                icon={<LeftOutlined />}
                size="small"
                title={`${-1 * mStep * 10}`}
                onClick={() => _adjustSlider(-1 * mStep * 10)}
            />
            <Slider
                className="w100"
                min={props.min}
                max={props.max}
                step={props.step}
                value={props.value}
                onChange={_handleOnChange}
            />
            <Button
                icon={<RightOutlined />}
                size="small"
                title={`+${mStep * 10}`}
                onClick={() => _adjustSlider(mStep * 10)}
            />
            <Button
                icon={<DoubleRightOutlined />}
                size="small"
                title={`+${mStep * 100}`}
                onClick={() => _adjustSlider(mStep * 100)}
            />
        </Flex>
    );
};

export default AdjustSlider;
